<template>
  <div id="app">
    <!-- نمایش TopNav در صورتی که شرط‌های لازم برقرار باشد -->
    <TopNav v-if="showTopNav" class="top-nav" />

    <!-- نمایش HomeNav فقط در مسیر /home -->
    <HomeNav v-if="isHome" class="home-nav" />

    <router-view class="content" />
    <BottomNav v-if="showNav" class="bottom-nav" />
  </div>
</template>

<script>
import TopNav from './components/TopNav.vue';
import BottomNav from './components/BottomNav.vue';
import HomeNav from './components/HomeNav.vue';

export default {
  name: 'App',
  components: {
    TopNav,
    BottomNav,
    HomeNav
  },
  data() {
    return {
      isHome: this.$route.name === 'Home',
    };
  },
  watch: {
    $route(to, from) {
      this.isHome = to.name === 'Home';
    }
  },
  computed: {
    showNav() {
      const noNavRoutes = ['/login', '/register', '/forgotpass', '/twofactor'];
      return !noNavRoutes.includes(this.$route.path);
    },
    showTopNav() {
      const hideTopNavRoutes = ['/login', '/register', '/forgotpass', '/twofactor', '/home'];
      return !hideTopNavRoutes.includes(this.$route.path);
    }
  }
}
</script>

<style>
/* استایل‌ها به همان صورت قبلی می‌مانند */
body, html, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#app {
  display: flex;
  flex-direction: column;
  background-color: #E4E4E4;
}

.content {
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 70px;
}

.content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.top-nav, .bottom-nav {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.top-nav {
  top: 0;
}

.bottom-nav {
  bottom: 0;
}
</style>
