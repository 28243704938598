<template>
  <div class="prop-container">
    <button class="new-prop-button" @click="addNewProp">ثبت پراپ جدید</button>

    <div class="stats-container">
      <div class="stat-box" v-for="stat in stats" :key="stat.title">
        <h3>{{ stat.title }}</h3>
        <p>{{ stat.value }}</p>
      </div>
    </div>

    <div class="props-list">
      <h3>لیست کل پراپ‌ها</h3>
      <table>
        <thead>
        <tr>
          <th>نام</th>
          <th>وضعیت</th>
          <th>درصد پیشرفت</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="prop in props" :key="prop.id">
          <td>{{ prop.name }}</td>
          <td>{{ prop.status }}</td>
          <td>
            <div class="progress-bar-container">
              <div class="progress-bar" :style="{ width: prop.progress + '%' }"></div>
            </div>
            {{ prop.progress }}%
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PropsView',
  data() {
    return {
      props: [],
      stats: [
        { title: 'تعداد پراپ‌ها', value: 0 },
        { title: 'فعال', value: 0 },
        { title: 'پاس شده', value: 0 },
        { title: 'رد شده', value: 0 },
      ]
    };
  },
  mounted() {
    this.fetchProps();
  },
  methods: {
    name: 'PropsView',
    fetchProps() {
      this.props = [
        { id: 1, name: 'Prop 1', status: 'فعال', progress: 70 },
        { id: 2, name: 'Prop 2', status: 'پاس شده', progress: 100 },
        { id: 3, name: 'Prop 3', status: 'رد شده', progress: 30 },
        { id: 4, name: 'Prop 4', status: 'فعال', progress: 50 },
        // تا 20 پراپ اضافه کنید
      ];

      this.stats[0].value = this.props.length;
      this.stats[1].value = this.props.filter(prop => prop.status === 'فعال').length;
      this.stats[2].value = this.props.filter(prop => prop.status === 'پاس شده').length;
      this.stats[3].value = this.props.filter(prop => prop.status === 'رد شده').length;
    },
    addNewProp() {
      alert('ثبت پراپ جدید کلیک شد!');
    }
  }
};
</script>

<style scoped>
.prop-container {
  padding: 20px;
  margin-bottom: 65px;
  margin-top: 60px;
}

.new-prop-button {
  display: block;
  margin: 0 auto 20px auto;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.new-prop-button:hover {
  background-color: #45a049;
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stat-box {
  width: 22%; /* تنظیم عرض ثابت برای باکس‌ها */
  margin: 10px 0;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .stat-box {
    width: 45%; /* تنظیم عرض برای نمایش دو باکس در هر ردیف */
  }
}

@media (max-width: 768px) {
  .stat-box {
    width: 100%; /* تنظیم عرض برای نمایش یک باکس در هر ردیف */
  }
}

.props-list {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.props-list table {
  width: 100%;
  border-collapse: collapse;
}

.props-list th, .props-list td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.progress-bar-container {
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 10px;
  background-color: #4CAF50;
  border-radius: 5px;
}
</style>
