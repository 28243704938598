<template>
  <div class="profile-container">
    <div class="profile-header">
      <img src="https://via.placeholder.com/150" alt="Profile Picture" class="profile-picture">
      <h1>{{ user.name }}</h1>
      <p>{{ user.email }}</p>
    </div>
    <div class="profile-details">
      <form @submit.prevent="updateProfile">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="user.name">
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="user.email">
        </div>
        <div class="form-group">
          <label for="country">Country:</label>
          <input type="text" id="country" v-model="user.country">
        </div>
        <div class="form-group">
          <label for="phone">Phone:</label>
          <input type="tel" id="phone" v-model="user.phone">
        </div>
        <div class="form-group">
          <label for="bio">Bio:</label>
          <textarea id="bio" v-model="user.bio"></textarea>
        </div>
        <div class="form-actions">
          <button type="submit" class="btn">Save Changes</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileView',
  data() {
    return {
      user: {
        name: 'John Doe',
        email: 'john.doe@example.com',
        country: 'USA',
        phone: '+1 234 567 890',
        bio: 'This is a short bio about John Doe.'
      }
    };
  },
  methods: {
    updateProfile() {
      alert('Profile updated');
      // Here you can add your logic to update the profile
    },
    logout() {
      alert('Logout clicked');
      // Here you can add your logic to handle logout
    }
  }
}
</script>

<style scoped>
.profile-container {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px; /* Ensure enough space for fixed top nav */
  margin-bottom: 65px;
  text-align: center;
}

.profile-header {
  margin-bottom: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-details {
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

.profile-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-actions {
  text-align: center;
}

.btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #e09f3e;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #d08b2f;
}

.btn-logout {
  background-color: #ff4e4e;
}

.btn-logout:hover {
  background-color: #e04343;
}
</style>
