<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-container">
      <div class="modal-header">
        <h2>Deposit Funds</h2>
        <button class="close-button" @click="close">&times;</button>
      </div>
      <div class="modal-body">
        <label for="deposit-amount">Enter Amount:</label>
        <input type="number" id="deposit-amount" v-model.number="amount" min="1" placeholder="Enter amount" />
      </div>
      <div class="modal-footer">
        <button class="cancel-button" @click="close">Cancel</button>
        <button class="submit-button" @click="submitDeposit">Deposit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepositModal',
  data() {
    return {
      amount: 0
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submitDeposit() {
      if (this.amount > 0) {
        this.$emit('deposit', this.amount);
        this.close();
      } else {
        alert('Please enter a valid amount.');
      }
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}

.modal-header h2 {
  margin: 0;
  font-size: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.modal-body {
  margin-bottom: 15px;
}

.modal-body label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

.modal-body input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  margin-bottom: 10px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.cancel-button,
.submit-button {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.submit-button {
  background-color: #127569;
  border: 1px solid #127569;
  color: #fff;
}

.submit-button:hover {
  background-color: #0e5a50;
  border-color: #0e5a50;
}

.cancel-button:hover {
  background-color: #ebebeb;
}
</style>
