<template>
  <div class="reset-password-container">
    <transition name="fade" @after-leave="resetNotification">
      <div v-if="notificationMessage" class="notification-popup" :class="{'error': !emailValid, 'success': emailValid}" @click="closeNotification">
        <span class="notification-icon">{{ emailValid ? '✔' : '✖' }}</span>
        <span>{{ notificationMessage }}</span>
        <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
      </div>
    </transition>
    <div class="reset-password-card">
      <div class="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="193.675" height="180.318" viewBox="0 0 193.675 180.318">
          <g id="Group_12" data-name="Group 12" transform="translate(-15.271 -41.096)">
            <path id="Path_116" data-name="Path 116" d="M60.638,221.414H165.664a43.366,43.366,0,0,0,36.187-67.187l-3.6-5.512a34.437,34.437,0,0,1-3.4-30.977l4.841-12.857c7.008-18.612-8.412-37.958-28.062-35.207a26.076,26.076,0,0,1-17.081-3.5L126.272,49.133a55.906,55.906,0,0,0-43.8-6.007L69.954,46.593a43.831,43.831,0,0,0-29.837,56.018l2.536,7.669A33.188,33.188,0,0,1,29.8,148.157a33.194,33.194,0,0,0-10.841,42.659l3.874,7.518A42.535,42.535,0,0,0,60.638,221.414Z" transform="translate(0 0)" fill="#e7e8ff"/>
            <path id="Path_117" data-name="Path 117" d="M433.1,405.272c.16,12.235-8.14,22.565-12.355,26.183h-6.46c4.8-16.324,12.742-24.441,17.191-27.143A1.069,1.069,0,0,1,433.1,405.272Z" transform="translate(-231.456 -210.597)" fill="#032068"/>
            <path id="Path_118" data-name="Path 118" d="M416.828,309.953c6.618,25.017-10.063,54.646-17.236,65.676H385.056c6.243-47.2,16.711-58.673,29.943-66.426A1.237,1.237,0,0,1,416.828,309.953Z" transform="translate(-214.501 -155.42)" fill="#fff"/>
            <path id="Path_119" data-name="Path 119" d="M414.307,308.868a.592.592,0,0,1,.879.356c3.26,12.325.79,25.838-3.43,37.7a136.092,136.092,0,0,1-13.532,27.162H384.777c3.107-23.141,7.237-37.519,12.185-47.057A41.731,41.731,0,0,1,414.307,308.868Zm2.128.022a1.883,1.883,0,0,0-2.778-1.144,43.019,43.019,0,0,0-17.84,18.68c-5.1,9.836-9.293,24.592-12.418,48.221l-.1.735h15.622l.191-.294a137.469,137.469,0,0,0,13.858-27.729C417.231,335.391,419.792,321.582,416.435,308.889Z" transform="translate(-213.483 -154.524)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_120" data-name="Path 120" d="M382.617,346.632c5.716,20.965-.827,42.751-5.106,50.934H366.853c-2.4-25.551,7.347-44.131,13.754-51.435A1.163,1.163,0,0,1,382.617,346.632Z" transform="translate(-203.726 -176.708)" fill="#032068"/>
            <path id="Path_121" data-name="Path 121" d="M415.864,340.052l3.477-9.822,1.217.435-7.965,22.5,5.232-1.58.372,1.244-6.141,1.854-7.244,20.461,6.519-2.437.451,1.217-7.536,2.817-4.038,11.405-1.217-.435,8.2-23.163L404.16,356l1.217-.436,2.5,7.051,7.254-20.489-2.26-7.59,1.237-.372Z" transform="translate(-222.585 -167.718)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_122" data-name="Path 122" d="M381.432,375.157l1.648-9.113,1.271.233-3.345,18.5,4.347-2.3.6,1.149-5.242,2.775-2.869,15.865,3.594-2.839.8,1.021-4.741,3.744-.9,4.953-1.272-.232,2.3-12.706-3.79-4.968,1.025-.79,3.077,4.033,3.2-17.723-3.07-3.334.948-.882Z" transform="translate(-207.993 -188.493)" fill="#fff" fill-rule="evenodd"/>
            <path id="Path_123" data-name="Path 123" d="M411.812,160.855l1.931-2.672a.6.6,0,0,1,1.093.357l-.014,3.219a.6.6,0,0,0,.25.492l2.856,2.064a.6.6,0,0,1-.318,1.092l-3.555.214a.6.6,0,0,0-.439.231l-2.409,3.083a.6.6,0,0,1-1.079-.385l.081-3.667a.605.605,0,0,0-.206-.468l-3-2.629a.6.6,0,0,1,.456-1.055l3.805.372A.6.6,0,0,0,411.812,160.855Z" transform="translate(-227.111 -67.773)" fill="#fff"/>
            <path id="Path_124" data-name="Path 124" d="M461.563,248.706l.934-.511a.6.6,0,0,1,.834.792l-.448.931a.6.6,0,0,0,.014.552l.62,1.133a.6.6,0,0,1-.756.85l-1.232-.5a.6.6,0,0,0-.5.02l-1.33.664a.6.6,0,0,1-.809-.812l.6-1.194a.605.605,0,0,0,.015-.511l-.6-1.382a.6.6,0,0,1,.866-.756l1.182.711A.6.6,0,0,0,461.563,248.706Z" transform="translate(-257.294 -119.957)" fill="#f4ad5e"/>
            <path id="Path_125" data-name="Path 125" d="M59.006,129.015a15.03,15.03,0,0,0,22.85,12.831l4.523,1.6a.98.98,0,0,0,1.285-1.135l-1.1-5a15.027,15.027,0,1,0-27.555-8.3Z" transform="translate(-25.369 -42.282)" fill="#fff"/>
            <path id="Path_126" data-name="Path 126" d="M73.582,113.863a14.7,14.7,0,1,0,7.653,27.253l.133-.081,4.67,1.657a.653.653,0,0,0,.856-.757L85.761,136.8l.077-.116a14.7,14.7,0,0,0-12.256-22.819Zm-15.353,14.7a15.353,15.353,0,1,1,28.23,8.364l1.074,4.867a1.306,1.306,0,0,1-1.712,1.513l-4.378-1.552a15.357,15.357,0,0,1-23.213-13.191Z" transform="translate(-24.918 -41.831)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_127" data-name="Path 127" d="M83.75,140.662a.321.321,0,0,1-.323-.327,7.318,7.318,0,0,1,.476-2.678,6.167,6.167,0,0,1,1.75-2.2A8.92,8.92,0,0,0,87.36,133.7a2.707,2.707,0,0,0,.423-1.442,1.98,1.98,0,0,0-2.2-2.277,2.3,2.3,0,0,0-1.679.62,2.2,2.2,0,0,0-.627,1.367.351.351,0,0,1-.341.317H79.492a.316.316,0,0,1-.319-.33,4.989,4.989,0,0,1,1.725-3.7,8.361,8.361,0,0,1,9.327-.068,4.784,4.784,0,0,1,1.651,3.894,4.613,4.613,0,0,1-.536,2.169,9.049,9.049,0,0,1-1.877,2.25l-1.143,1.038a3.639,3.639,0,0,0-1.228,2.3l-.036.517a.327.327,0,0,1-.326.3Zm-.736,4.136a1.917,1.917,0,0,1,.635-1.482,2.354,2.354,0,0,1,1.651-.593,2.3,2.3,0,0,1,1.637.593,2.016,2.016,0,0,1,.014,2.951,2.324,2.324,0,0,1-1.651.579,2.376,2.376,0,0,1-1.665-.579A1.922,1.922,0,0,1,83.014,144.8Z" transform="translate(-37.067 -49.713)" fill="#f4ad5e"/>
            <path id="Path_128" data-name="Path 128" d="M170.332,90.208a6.678,6.678,0,0,1,6.679-6.678h75.979a6.678,6.678,0,0,1,6.678,6.678V238.772a6.678,6.678,0,0,1-6.678,6.679H177.011a6.678,6.678,0,0,1-6.679-6.679Z" transform="translate(-89.946 -24.614)" fill="#fff"/>
            <path id="Path_129" data-name="Path 129" d="M169.006,89.439a7.235,7.235,0,0,1,7.235-7.235H252.22a7.235,7.235,0,0,1,7.235,7.235V238a7.235,7.235,0,0,1-7.235,7.235H176.241A7.235,7.235,0,0,1,169.006,238Zm7.235-6.122a6.122,6.122,0,0,0-6.122,6.122V238a6.122,6.122,0,0,0,6.122,6.122H252.22A6.122,6.122,0,0,0,258.341,238V89.439a6.122,6.122,0,0,0-6.122-6.122Z" transform="translate(-89.177 -23.846)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_130" data-name="Path 130" d="M265.746,186.876a27.827,27.827,0,1,1-27.827-27.827A27.827,27.827,0,0,1,265.746,186.876Z" transform="translate(-113.01 -68.421)" fill="#e7e8ff"/>
            <path id="Path_131" data-name="Path 131" d="M335.091,162.572a12.8,12.8,0,1,1-12.8-12.8A12.8,12.8,0,0,1,335.091,162.572Z" transform="translate(-170.667 -63.04)" fill="#fff"/>
            <path id="Path_132" data-name="Path 132" d="M321.521,149.559A12.244,12.244,0,1,0,333.765,161.8,12.244,12.244,0,0,0,321.521,149.559ZM308.164,161.8a13.357,13.357,0,1,1,13.357,13.357A13.357,13.357,0,0,1,308.164,161.8Z" transform="translate(-169.898 -62.27)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_133" data-name="Path 133" d="M326.5,169.213l-3.756,3.756,3.757,3.757,3.756-3.756,3.756,3.756,3.756-3.757-3.756-3.756,3.757-3.757-3.757-3.757-3.757,3.757L326.5,161.7l-3.757,3.757Z" transform="translate(-178.355 -69.958)" fill="#f4ad5e" fill-rule="evenodd"/>
            <path id="Path_134" data-name="Path 134" d="M230.4,88.958l-2.9-5.429h41.318l-3.4,5.673a5.565,5.565,0,0,1-4.772,2.7H235.312A5.565,5.565,0,0,1,230.4,88.958Z" transform="translate(-123.111 -24.614)" fill="#032068"/>
            <path id="Path_135" data-name="Path 135" d="M259.3,86.866H171.584c2.9-2.9,5.677-3.443,6.7-3.35H252.6A8.38,8.38,0,0,1,259.3,86.866Z" transform="translate(-90.672 -24.601)" fill="#032068"/>
            <path id="Path_136" data-name="Path 136" d="M260.042,461.559h-87.06c3.018,2.95,4.761,3.5,5.825,3.4h75.409A6.371,6.371,0,0,0,260.042,461.559Z" transform="translate(-91.483 -243.898)" fill="#032068"/>
            <path id="Path_137" data-name="Path 137" d="M200.814,132.33a1.113,1.113,0,0,1,1.113-1.113h29.639a1.113,1.113,0,0,1,1.113,1.113v1.188a1.113,1.113,0,0,1-1.113,1.113H201.927a1.113,1.113,0,0,1-1.113-1.113Z" transform="translate(-107.628 -52.276)" fill="#f4ad5e"/>
            <path id="Path_138" data-name="Path 138" d="M200.814,311.248a1.113,1.113,0,0,1,1.113-1.113h29.639a1.113,1.113,0,0,1,1.113,1.113v1.188a1.113,1.113,0,0,1-1.113,1.113H201.927a1.113,1.113,0,0,1-1.113-1.113Z" transform="translate(-107.628 -156.061)" fill="#032068"/>
            <path id="Path_139" data-name="Path 139" d="M200.814,381.489a1.113,1.113,0,0,1,1.113-1.113h17.809a1.113,1.113,0,0,1,1.113,1.113V382.6a1.113,1.113,0,0,1-1.113,1.113H201.927a1.113,1.113,0,0,1-1.113-1.113Z" transform="translate(-107.628 -196.806)" fill="#032068"/>
            <path id="Path_140" data-name="Path 140" d="M199.488,330.359a1.67,1.67,0,0,1,1.67-1.67h59.55a1.67,1.67,0,0,1,1.67,1.67v11.131a1.67,1.67,0,0,1-1.67,1.67h-59.55a1.67,1.67,0,0,1-1.67-1.67Zm1.67-.557a.557.557,0,0,0-.556.557v11.131a.556.556,0,0,0,.556.556h59.55a.556.556,0,0,0,.556-.556V330.359a.557.557,0,0,0-.556-.557Z" transform="translate(-106.859 -166.824)" fill="#e7e8ff" fill-rule="evenodd"/>
            <path id="Path_141" data-name="Path 141" d="M199.488,399.275a1.67,1.67,0,0,1,1.67-1.67h59.55a1.67,1.67,0,0,1,1.67,1.67v11.131a1.67,1.67,0,0,1-1.67,1.67h-59.55a1.67,1.67,0,0,1-1.67-1.67Zm1.67-.556a.556.556,0,0,0-.556.556v11.131a.556.556,0,0,0,.556.556h59.55a.556.556,0,0,0,.556-.556V399.275a.556.556,0,0,0-.556-.556Z" transform="translate(-106.859 -206.8)" fill="#e7e8ff" fill-rule="evenodd"/>
            <path id="Path_142" data-name="Path 142" d="M366.6,102.477v4.552h-1.113v-4.552Z" transform="translate(-203.149 -35.605)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_143" data-name="Path 143" d="M359.824,103.832v3.983h-1.113v-3.983Z" transform="translate(-199.219 -36.391)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_144" data-name="Path 144" d="M353.049,106.542v2.845h-1.113v-2.845Z" transform="translate(-195.289 -37.963)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_145" data-name="Path 145" d="M346.273,109.252v1.707H345.16v-1.707Z" transform="translate(-191.359 -39.535)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_146" data-name="Path 146" d="M263.659,186.435a9.248,9.248,0,0,0-9.171,9.322v6.383a1.371,1.371,0,0,0,2.743,0v-6.557a6.429,6.429,0,1,1,12.856,0V201.8a1.371,1.371,0,0,0,2.743,0v-6.041A9.248,9.248,0,0,0,263.659,186.435Z" transform="translate(-138.762 -84.307)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_147" data-name="Path 147" d="M248.463,221.24a1.67,1.67,0,0,1,1.67-1.67h20.062a1.67,1.67,0,0,1,1.67,1.67v15a1.67,1.67,0,0,1-1.67,1.67H250.133a1.67,1.67,0,0,1-1.67-1.67Z" transform="translate(-135.268 -103.527)" fill="#f4ad5e"/>
            <path id="Path_148" data-name="Path 148" d="M274.531,237.363a2.381,2.381,0,1,0-2.834.111l-.531,2.232a.529.529,0,0,0,.515.652h2.894a.529.529,0,0,0,.515-.652Z" transform="translate(-148.142 -111.39)" fill="#fff" fill-rule="evenodd"/>
            <path id="Path_149" data-name="Path 149" d="M339.165,321.372H325.626a1.113,1.113,0,0,1-.982-1.638l6.769-12.665a1.113,1.113,0,0,1,1.964,0l6.769,12.665A1.113,1.113,0,0,1,339.165,321.372Z" transform="translate(-179.381 -153.941)" fill="#032068"/>
            <path id="Path_150" data-name="Path 150" d="M341.512,323.346h1.219l.192-5.26h-1.6Zm1.455,1.639a.889.889,0,0,0-.236-.64.792.792,0,0,0-.6-.254.776.776,0,0,0-.593.254.88.88,0,0,0-.241.64.9.9,0,0,0,.241.645.785.785,0,0,0,.593.249.8.8,0,0,0,.6-.249A.908.908,0,0,0,342.967,324.984Z" transform="translate(-189.118 -160.674)" fill="#fff"/>
            <path id="Path_151" data-name="Path 151" d="M216.9,343.359a1.417,1.417,0,1,1-1.417-1.417A1.417,1.417,0,0,1,216.9,343.359Z" transform="translate(-115.315 -174.512)" fill="#f4ad5e"/>
            <path id="Path_152" data-name="Path 152" d="M233.1,343.359a1.417,1.417,0,1,1-1.417-1.417A1.417,1.417,0,0,1,233.1,343.359Z" transform="translate(-124.712 -174.512)" fill="#f4ad5e"/>
            <path id="Path_153" data-name="Path 153" d="M249.3,343.359a1.417,1.417,0,1,1-1.417-1.417A1.417,1.417,0,0,1,249.3,343.359Z" transform="translate(-134.109 -174.512)" fill="#f4ad5e"/>
            <path id="Path_154" data-name="Path 154" d="M265.5,343.359a1.417,1.417,0,1,1-1.417-1.417A1.417,1.417,0,0,1,265.5,343.359Z" transform="translate(-143.505 -174.512)" fill="#f4ad5e"/>
            <path id="Path_155" data-name="Path 155" d="M281.7,343.359a1.417,1.417,0,1,1-1.417-1.417A1.417,1.417,0,0,1,281.7,343.359Z" transform="translate(-152.902 -174.512)" fill="#f4ad5e"/>
            <path id="Path_156" data-name="Path 156" d="M297.9,343.359a1.417,1.417,0,1,1-1.417-1.417A1.417,1.417,0,0,1,297.9,343.359Z" transform="translate(-162.298 -174.512)" fill="#f4ad5e"/>
            <path id="Path_157" data-name="Path 157" d="M115.053,213.712c-7.892,5.584-4.575,15.511-3.088,19.358l-.652,7.849c.675,2.471,3.122,7.413,7.514,7.413s6.4-4.219,6.862-6.328L125,236.047c5.147-1.861,5.49-9.306,5.147-15.263C129.744,213.712,122,208.793,115.053,213.712Z" transform="translate(-54.945 -98.977)" fill="#efcb96"/>
            <path id="Path_158" data-name="Path 158" d="M106.8,222.093l-.684,6.749a15.9,15.9,0,0,1-1.025-3.75c-.342-2.25-5.811-10.874-4.1-17.249,1.367-5.1,5.128-4.875,6.837-4.125,1.025-1.875,7.179-8.25,16.066-7.5s9.914,13.5,2.735,15.374a33.392,33.392,0,0,1-14.7,0,46.978,46.978,0,0,0-1.71,4.875c-1.025,3.375-1.025,4.125-1.367,1.875s-1.709-6.375-3.418-2.25C104.065,219.393,105.774,221.468,106.8,222.093Z" transform="translate(-49.53 -89.947)" fill="#032068"/>
            <path id="Path_159" data-name="Path 159" d="M127.105,269.322c2.352,1.386,5.989.577,7.514,0l.327,2.425C131.548,272.579,128.3,270.477,127.105,269.322Z" transform="translate(-64.871 -132.387)" fill="#032068"/>
            <path id="Path_160" data-name="Path 160" d="M63.9,282.5c4.922,0,7.305-2.74,8.021-4.378a.5.5,0,0,1,.474-.316c2.716.137,6.569,1.786,7.535,2.118a.45.45,0,0,1,.073.032c7.593,4.08,14.793,11.3,19.305,37.344,1.462,10.868,4.073,33.545,2.82,37.307s-9.923,4.285-14.1,4.076H33.188c-1.451,0-1-2.288-.39-4.025a.608.608,0,0,0-.649-.768c-2.5.346-7.267-.108-12.435-3.986-10.027-8.151,1.567-40.756,9.087-56.118,5.909-12.07,19.958-15.652,26.593-15.975a.512.512,0,0,1,.485.322C56.6,279.773,58.986,282.5,63.9,282.5Z" transform="translate(-0.314 -137.309)" fill="#f4ad5e"/>
            <path id="Path_161" data-name="Path 161" d="M30.688,432.773c-3.447-7.325-10.744-14.567-14.648-17.861a.457.457,0,0,0-.758.337c-.095,3.37.358,9.535,2.864,13.182,2.558,3.722,8.729,4.855,12.11,5A.457.457,0,0,0,30.688,432.773Z" transform="translate(0 -216.775)" fill="#032068"/>
            <path id="Path_162" data-name="Path 162" d="M85.227,412.917c-14.289,14.547-26.217,19.229-30.395,19.751l-.627,2.822C66.488,433.483,80,419.605,85.227,412.917Z" transform="translate(-22.584 -215.682)" fill="#032068"/>
            <path id="Path_163" data-name="Path 163" d="M136.285,369.381V348.139a.484.484,0,0,1,.4-.478l10.3-1.718a.483.483,0,0,1,.564.484l-.3,20.672a.484.484,0,0,1-.376.465l-10.005,2.288A.484.484,0,0,1,136.285,369.381Z" transform="translate(-70.196 -176.829)" fill="#fff"/>
            <path id="Path_164" data-name="Path 164" d="M146.246,344.8a.968.968,0,0,1,1.127.969l-.3,20.672a.968.968,0,0,1-.752.93l-10,2.288a.968.968,0,0,1-1.183-.944V347.47a.968.968,0,0,1,.809-.955Zm.159.955-10.3,1.718v21.242l10-2.288Z" transform="translate(-69.528 -176.16)" fill="#032068" fill-rule="evenodd"/>
            <path id="Path_165" data-name="Path 165" d="M60.425,371.2c-.5,1-5.849,3.553-8.46,4.7,2.758-.753,18.905-6.375,26.635-9.092-2.193,0-15.041,3.762-15.354,3.135-.251-.5.731-6.9,1.253-10.033C63.35,363.254,60.927,370.193,60.425,371.2Z" transform="translate(-21.285 -184.935)" fill="#032068"/>
            <path id="Path_166" data-name="Path 166" d="M122.257,366.853a8.066,8.066,0,0,1-3.134,3.135l2.193,7.211c2.82-1.568,7.521-1.254,10.967-3.135s2.82-10.973.94-10.346c-1.5.5-5.223,2.09-6.894,2.822V362.15C124.137,363.091,123.823,364.345,122.257,366.853Z" transform="translate(-60.241 -186.234)" fill="#efcb96"/>
            <path id="Path_167" data-name="Path 167" d="M162.1,280.293c-2.444,0-8.421,5.707-8.421,8.425-.543,2.174,13.853,33.156,24.447,38.864s19.287-1.359,18.472-12.23C195.742,303.937,164.936,280.293,162.1,280.293Z" transform="translate(-80.278 -138.751)" fill="#f4ad5e"/>
            <path id="Path_168" data-name="Path 168" d="M234.892,361.839a20.759,20.759,0,0,0-15.212,14.4c2.354.634,6.791,2.718,11.952-2.446C235.332,370.095,235.435,364.376,234.892,361.839Z" transform="translate(-118.571 -186.053)" fill="#032068"/>
            <path id="Path_169" data-name="Path 169" d="M166.194,325.9c6.519,10.871,12.314,14.132,14.4,14.4l.543,3.533c-1.63-.272-5.541-2.718-11.409-10.328-8.258-10.436-14.4-26.453-16.027-33.156C155.057,304.976,158.045,312.314,166.194,325.9Z" transform="translate(-80.298 -150.389)" fill="#032068"/>
            <path id="Path_170" data-name="Path 170" d="M140.8,260.983c.381.58,3.927,6.369,5.433,9.191-1.427,1.451-4.424,4.354-4.995,4.354-.713,0-4.757-1.209-6.422-3.386s.951-4.837.951-5.079-4.281-2.177-4.994-3.144.247-1.979,1.334-1.708c.621,0,4.618,2.174,5.976,2.718C138.658,263.734,139.445,263.113,140.8,260.983Z" transform="translate(-66.859 -127.55)" fill="#efcb96"/>
            <path id="Path_171" data-name="Path 171" d="M130.412,264.76c.218,1.522,2.264,2.809,3.259,3.262l.815-.544c-.362-.181-1.358-.707-2.445-1.359A4.732,4.732,0,0,1,130.412,264.76Z" transform="translate(-66.79 -129.741)" fill="#032068"/>
            <path id="Path_172" data-name="Path 172" d="M137.871,279.9a3.817,3.817,0,0,1-.272-5.164l1.358-.272c-1.63,2.718-.634,4.53,0,4.892Z" transform="translate(-70.446 -135.372)" fill="#032068"/>
          </g>
        </svg>
      </div>
      <h1>Forgot Your Password?</h1>
      <p>Please enter your email or phone number to receive a link to reset your password.</p>
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <input type="text" v-model="accountInfo" :class="{'input-invalid': !emailValid && accountInfo, 'input-valid': emailValid}" placeholder="Email or Phone Number" required>
        </div>
        <button type="submit">Send Reset Link</button>
      </form>
      <div class="footer-links">
        <router-link to="/login">Return to Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountInfo: '',
      notificationMessage: '',
      progressWidth: 0,
      progressInterval: null
    };
  },
  computed: {
    emailValid() {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.accountInfo);
    }
  },
  methods: {
    handleSubmit() {
      if (!this.emailValid) {
        this.notificationMessage = 'Please enter a valid email format';
      } else {
        this.notificationMessage = 'Reset link sent successfully';
      }
      this.startProgressBar();
      setTimeout(() => {
        this.notificationMessage = '';
      }, 3500);
    },
    startProgressBar() {
      this.progressWidth = 100;
      if (this.progressInterval) clearInterval(this.progressInterval);
      this.progressInterval = setInterval(() => {
        if (this.progressWidth > 0) {
          this.progressWidth -= 0.5;
        } else {
          clearInterval(this.progressInterval);
        }
      }, 25);
    },
    closeNotification() {
      this.notificationMessage = '';
    },
    resetNotification() {
      this.progressWidth = 0;
      clearInterval(this.progressInterval);
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

* {
  font-family: 'Nunito', sans-serif;
}

.reset-password-container {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 310px;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  transition: opacity 1s ease;
  cursor: pointer;
}

.notification-popup.error {
  background-color: #f44336;
  color: #fff;
}

.notification-popup.success {
  background-color: #4caf50;
  color: #fff;
}

.notification-icon {
  font-size: 1rem;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.5s linear;
}

.reset-password-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.svg-container {
  margin-top: 10px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

.input-group input:focus {
}

.input-invalid {
  border-color: red !important;
}

.input-invalid:focus {
  outline-color: red !important;
}

.input-valid {
  border-color: green !important;
}

.input-valid:focus {
  outline-color: green !important;
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #6c63ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #5753d6;
}

.footer-links {
  margin-top: 1rem;
}

.footer-links a {
  color: #6c63ff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

h1 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.5rem;
}

/* Animation for fading */
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
