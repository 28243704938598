import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Props from '../views/Props.vue'
import Funds from '../views/Funds.vue'
import Wallet from '../views/Wallet.vue'
import Profile from '../views/Profile.vue'
import Notifications from '../views/Notifications.vue'
import Login from '../views/Login.vue'
import Register from "@/views/Register.vue";
import ForgetPass from "@/views/ForgotPass.vue";
import Referrals from "@/views/Referrals.vue";
import TwoFactor from "@/views/TwoFactor.vue";
import Dashboard from "@/views/Dashboard.vue";

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/forgotpass', name: 'ForgotPass', component: ForgetPass },
  { path: '/twofactor', name: 'TwoFactor', component: TwoFactor },
  { path: '/home', name: 'Home', component: Home },
  { path: '/props', name: 'Props', component: Props },
  { path: '/funds', name: 'Funds', component: Funds },
  { path: '/wallet', name: 'Wallet', component: Wallet },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/referrals', name: 'Referrals', component: Referrals },
  { path: '/notifications', name: 'Notifications', component: Notifications },
  { path: '/dash', name: 'Dashboard', component: Dashboard }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
