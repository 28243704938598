
<template>
  <div class="wallet-container">
    <div class="row balance-row">
      <div class="balance-section">
        <div class="balance-header">
          <div class="balance-info">
            <svg class="balance-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="23.558" height="21.157" viewBox="0 0 23.558 21.157">
                <g id="Group_59" data-name="Group 59" transform="translate(0 0)">
                  <path id="Path_221" data-name="Path 221" d="M.25,90.97v13.9a4.532,4.532,0,0,0,4.53,4.534h14.5a4.532,4.532,0,0,0,4.53-4.534v-.3H17.465a3.929,3.929,0,0,1,0-7.858h6.342v-.3a4.532,4.532,0,0,0-4.53-4.534H16.469a4.533,4.533,0,0,0-4.44-3.627H2.968A2.719,2.719,0,0,0,.25,90.97Zm14.342.907H2.968a.907.907,0,0,1,0-1.813h9.061A2.719,2.719,0,0,1,14.592,91.877Zm-10.718,13a.907.907,0,0,1,.906-.907H9.613a.907.907,0,0,1,0,1.813H4.78A.907.907,0,0,1,3.874,104.874Z" transform="translate(-0.25 -88.25)" fill="#fff" fill-rule="evenodd"/>
                  <path id="Path_222" data-name="Path 222" d="M21.213,96.75v4.231H14.866a2.116,2.116,0,1,1,0-4.231Zm-6.347.907a1.209,1.209,0,1,0,1.209,1.209A1.209,1.209,0,0,0,14.866,97.657Z" transform="translate(2.345 -86.474)" fill="#fff" fill-rule="evenodd"/>
                </g>
              </svg>
            </svg>
            <span class="balance-text">Balance:</span>
          </div>
          <div class="balance-amount">$ 12,222.70</div>
        </div>
      </div>
    </div>
    <div class="row actions-row">
      <button class="action-button" @click="openDepositModal">
        <svg class="action-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.987" height="21.987" viewBox="0 0 21.987 21.987">
            <path id="Path_392" data-name="Path 392" d="M129.99,10.993a5,5,0,0,0-4.712,3.331h-8.057A2.22,2.22,0,0,1,115,12.1V3.221A2.22,2.22,0,0,1,117.221,1h12.214a2.22,2.22,0,0,1,2.221,2.221v8.057A4.986,4.986,0,0,0,129.99,10.993Zm0,0a5,5,0,1,0,5,5A5,5,0,0,0,129.99,10.993ZM115,5.442h16.656m-13.325,5.552h3.331m8.328,3.331V15.99m0,0v1.666m0-1.666h1.666m-1.666,0h-1.666" transform="translate(-114)" fill="none" stroke="#127569" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>
        </svg>
        <span class="action-text">Deposit</span>
      </button>
      <button class="action-button" @click="openWithdrawModal">
        <svg class="action-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.987" height="21.987" viewBox="0 0 21.987 21.987">
            <path id="Path_393" data-name="Path 393" d="M243.99,10.993a5,5,0,0,0-4.712,3.331h-8.057A2.22,2.22,0,0,1,229,12.1V3.221A2.22,2.22,0,0,1,231.221,1h12.214a2.22,2.22,0,0,1,2.221,2.221v8.057A4.986,4.986,0,0,0,243.99,10.993Zm0,0a5,5,0,1,0,5,5A5,5,0,0,0,243.99,10.993ZM229,5.442h16.656m-13.325,5.552h3.331m9.993,5h-3.331" transform="translate(-228)" fill="none" stroke="#127569" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>
        </svg>
        <span class="action-text">Withdraw</span>
      </button>
    </div>
    <div class="transactions-section">
      <h3>Recent Transactions</h3>
      <table class="transactions-table">
        <thead>
        <tr>
          <th>#</th>
          <th>Date & Time</th>
          <th>Amount</th>
          <th>Description</th>
          <th>Balance</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(transaction, index) in transactions" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <div class="data">{{ transaction.date }}</div>
            <div class="time">{{ transaction.time }}</div> <!-- نمایش ساعت در خط دوم -->
          </td>

          <td :class="{ 'text-red': transaction.amount < 0, 'text-green': transaction.amount > 0 }">
            {{ transaction.amount < 0 ? '-' : '+' }}${{ Math.abs(transaction.amount).toFixed(2) }}
          </td>
          <td>
            <div>{{ transaction.description }}</div>
          </td>
          <td>
            {{ transaction.balanceAfter !== undefined && transaction.balanceAfter !== null ? `$${transaction.balanceAfter.toFixed(2)}` : '$0' }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal برای واریز -->
    <DepositModal v-if="isDepositModalOpen" @close="closeDepositModal" @deposit="handleDeposit" />

    <!-- Modal برای برداشت -->
    <WithdrawModal v-if="isWithdrawModalOpen" @close="closeWithdrawModal" @withdraw="handleWithdraw" />

  </div>
</template>

<script>
import DepositModal from '@/components/DepositModal.vue';
import WithdrawModal from '@/components/WithdrawModal.vue';



export default {
  name: 'Wallet',
  components: {
    DepositModal,
    WithdrawModal
  },
  data() {
    return {
      balance: 11923.45, // موجودی اولیه
      transactions: [
        { date: '2024-07-01', time: '09:00 AM', description: 'Grocery Shopping', amount: -50.25, balanceAfter: 9949.75 },
        { date: '2024-07-02', time: '12:30 PM', description: 'Salary', amount: 1500, balanceAfter: 11449.75 },
        { date: '2024-07-03', time: '10:00 AM', description: 'Utility Bill', amount: -120, balanceAfter: 11329.75 },
        { date: '2024-07-04', time: '08:15 AM', description: 'Coffee', amount: -5.75, balanceAfter: 11324.00 },
        { date: '2024-07-05', time: '03:45 PM', description: 'Online Purchase', amount: -80, balanceAfter: 11244.00 },
        { date: '2024-07-06', time: '07:30 PM', description: 'Dinner', amount: -45.30, balanceAfter: 11198.70 },
        { date: '2024-07-07', time: '11:00 AM', description: 'Freelance Work', amount: 300, balanceAfter: 11498.70 },
        { date: '2024-07-08', time: '06:45 AM', description: 'Gym Membership', amount: -60, balanceAfter: 11438.70 },
        { date: '2024-07-09', time: '10:30 AM', description: 'Gift', amount: -150, balanceAfter: 11288.70 },
        { date: '2024-07-10', time: '04:00 PM', description: 'Rent', amount: -750, balanceAfter: 10538.70 },
        { date: '2024-07-11', time: '01:00 PM', description: 'Bonus', amount: 500, balanceAfter: 11038.70 },
        { date: '2024-07-12', time: '11:45 AM', description: 'Groceries', amount: -80.25, balanceAfter: 10958.45 },
        { date: '2024-07-13', time: '02:20 PM', description: 'Electricity Bill', amount: -90, balanceAfter: 10868.45 },
        { date: '2024-07-14', time: '09:50 AM', description: 'Water Bill', amount: -30, balanceAfter: 10838.45 },
        { date: '2024-07-15', time: '08:10 AM', description: 'Internet Bill', amount: -45, balanceAfter: 10793.45 },
        { date: '2024-07-16', time: '07:00 AM', description: 'Mobile Recharge', amount: -20, balanceAfter: 10773.45 },
        { date: '2024-07-17', time: '05:30 PM', description: 'Petrol', amount: -50, balanceAfter: 10723.45 },
        { date: '2024-07-18', time: '07:20 PM', description: 'Dining Out', amount: -100, balanceAfter: 10623.45 },
        { date: '2024-07-19', time: '02:50 PM', description: 'Shopping', amount: -200, balanceAfter: 10423.45 },
        { date: '2024-07-20', time: '12:00 PM', description: 'Salary', amount: 1500, balanceAfter: 11923.45 }
      ],
      isDepositModalOpen: false, // کنترل باز و بسته شدن مودال واریز
      isWithdrawModalOpen: false, // کنترل باز و بسته شدن مودال برداشت
    };
  },
  methods: {
    openDepositModal() {
      this.isDepositModalOpen = true;
    },
    closeDepositModal() {
      this.isDepositModalOpen = false;
    },
    openWithdrawModal() {
      this.isWithdrawModalOpen = true;
    },
    closeWithdrawModal() {
      this.isWithdrawModalOpen = false;
    },
    handleDeposit(amount) {
      if (amount > 0) {
        this.balance += amount;
        this.transactions.push({
          date: new Date().toISOString().split('T')[0],
          time: new Date().toLocaleTimeString(),
          description: 'Deposit',
          amount: amount,
          balanceAfter: this.balance
        });
        this.closeDepositModal();
      }
    },
    handleWithdraw(amount) {
      if (amount > 0 && amount <= this.balance) {
        this.balance -= amount;
        this.transactions.push({
          date: new Date().toISOString().split('T')[0],
          time: new Date().toLocaleTimeString(),
          description: 'Withdraw',
          amount: -amount,
          balanceAfter: this.balance
        });
        this.closeWithdrawModal();
      }
    }
  }
}
</script>

<style scoped>
.wallet-container {
  margin-bottom: 65px;
  margin-top: 60px;
  padding: 10px;
}

* {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;

}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.balance-row {
  width: 100%;
  margin-top: 0;
}

.balance-section {
  background-color: #127569;
  padding: 20px;
  border-radius: 10px;
  height: 80px;
  max-width: 500px;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.balance-header {
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balance-info {
  display: flex;
  align-items: center;
}

.balance-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.balance-text {
  font-size: 18px;
}

.balance-amount {
  font-size: 24px;
}

.actions-row {
  max-width: 320px;
  margin: -25px auto 10px auto;
  height: 40px;
  justify-content: space-between;
  width: 90%;

}

.action-button {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 10px;
  padding: 10px 7px;
  border-width: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(50% - 10px);
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}


.action-button:hover {
  background-color: #e8f5f4;
}

.action-icon {

  width: 22px;
  height: 22px;
  margin-right: 4px;
  margin-top: 2px;
}

.action-text {
  font-size: 13px;
  font-weight: bold;
  color: #127569;
}

.transactions-section {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.transactions-section h3 {
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
  text-align: center;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  outline: 1px solid #d5d5d5;
  border-radius: 10px;
}

.transactions-table th {
  white-space: nowrap;
}

.transactions-table th,
.transactions-table td {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd; /* افزودن خطوط عمودی */
  vertical-align: middle;
}

.transactions-table tr:last-child td {
  border-bottom: none;
}

.transactions-table th:last-child,
.transactions-table td:last-child {
  border-right: none; /* حذف خط آخرین ستون */
}

.transactions-table th {
  background-color: #f7f7f7;
  color: #333;
  font-size: 14px;
}

.time, .data {
  white-space: nowrap;
}

.transactions-table td {
  color: #333;
  font-size: 14px;
}

.transactions-table td div {
  font-size: 12px;
}

.text-red {
  color: red !important;
}

.text-green {
  color: green !important;
}


.transactions-table td:nth-child(1),
.transactions-table th:nth-child(1) {
  width: 5%;
}

.transactions-table td:nth-child(2),
.transactions-table th:nth-child(2),
.transactions-table td:nth-child(4),
.transactions-table th:nth-child(4) {
  width: 20%;
}

.transactions-table td:nth-child(3),
.transactions-table th:nth-child(3),
.transactions-table td:nth-child(5),
.transactions-table th:nth-child(5) {
  width: 20%;
}

/* تغییر اندازه فونت و سلول‌ها در دستگاه‌های کوچکتر */
/* تغییر اندازه فونت و سلول‌ها در دستگاه‌های کوچکتر */
/* تغییر اندازه فونت و سلول‌ها در دستگاه‌های کوچکتر */
/* تغییر اندازه فونت و سلول‌ها در دستگاه‌های بسیار کوچک (320px تا 370px) */
@media (max-width: 370px) {
  .transactions-table th,
  .transactions-table td {
    font-size: 10px;
    padding: 2px;
  }

  .transactions-section h3 {
    font-size: 14px;
  }

  .transactions-table td div {
    font-size: 9px;
    line-height: 1.1;
  }
}

/* تغییر اندازه فونت و سلول‌ها در دستگاه‌های کوچک (370px تا 420px) */
@media (min-width: 371px) and (max-width: 420px) {
  .transactions-table th,
  .transactions-table td {
    font-size: 11px;
    padding: 3px;
  }

  .transactions-section h3 {
    font-size: 15px;
  }

  .transactions-table td div {
    font-size: 10px;
    line-height: 1.1;
  }
}

/* تغییر اندازه فونت و سلول‌ها در دستگاه‌های متوسط (420px تا 480px) */
@media (min-width: 421px) and (max-width: 480px) {
  .transactions-table th,
  .transactions-table td {
    font-size: 12px;
    padding: 4px;
  }

  .transactions-section h3 {
    font-size: 16px;
  }

  .transactions-table td div {
    font-size: 11px;
    line-height: 1.2;
  }
}

/* تنظیمات کلی برای جلوگیری از شکستن خطوط در ستون‌های شماره، مبلغ و بالانس */
.transactions-table td:nth-child(1),
.transactions-table td:nth-child(3),
.transactions-table td:nth-child(5) {
  white-space: nowrap;
}

/* جلوگیری از بیرون زدن جدول از باکس */
.transactions-section {
  overflow-x: auto;
  border-radius: 10px;
}

.transactions-table {
  width: 100%;
  table-layout: fixed;
  border-radius: 10px;
}

.transactions-table th,
.transactions-table td {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  vertical-align: middle;
  word-wrap: break-word;
}

/* تنظیم خاص برای ستون تاریخ و ساعت */
/* تنظیمات برای نمایش دو ردیفی تاریخ و ساعت */
.transactions-table td div:first-child {
  font-weight: bold;
  margin-bottom: 3px; /* فاصله کمی بین تاریخ و ساعت */
}

/* تنظیمات کلی برای سلول‌های تاریخ و ساعت */
.transactions-table td:nth-child(2) div {
  line-height: 1.2;
}

.transactions-table td div {
  font-size: 12px;
}

/* افزایش عرض ستون شماره */
.transactions-table td:nth-child(1),
.transactions-table th:nth-child(1) {
  width: 10%;
}

.transactions-table td:nth-child(2),
.transactions-table th:nth-child(2) {
  width: 21%;
}

/* سایر ستون‌ها */
.transactions-table td:nth-child(3),
.transactions-table th:nth-child(3),
.transactions-table td:nth-child(4),
.transactions-table th:nth-child(4),
.transactions-table td:nth-child(5),
.transactions-table th:nth-child(5) {
  width: 20%;
}

</style>
