<template>
  <div class="register-container">
    <transition name="fade" @after-leave="resetNotification">
      <div v-if="notificationMessage" class="notification-popup" :class="notificationClass" @click="closeNotification">
        <span class="notification-icon">{{ notificationIcon }}</span>
        <span>{{ notificationMessage }}</span>
        <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
      </div>
    </transition>
    <div class="register-card">
      <div class="svg-container"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="209.03" height="180.45" viewBox="0 0 209.03 180.45">
        <g id="Group_10" data-name="Group 10" transform="translate(-15.066 -47.939)">
          <path id="Path_1" data-name="Path 1" d="M115.19,227.857h75.718a52.586,52.586,0,0,0,35.284-13.59l11.944-10.809a38.122,38.122,0,0,0,1.325-55.291,38.132,38.132,0,0,1-10.884-21.92l-3.755-27.822a52.448,52.448,0,0,0-17.382-32.4l-8.791-7.717a41.74,41.74,0,0,0-60.888,6.283l-.788,1.049a39.092,39.092,0,0,1-31.253,15.6,39.057,39.057,0,0,0-38.832,34.68l-.807,7.2A47.075,47.075,0,0,0,72.6,152.76l7.735,12.767a31.759,31.759,0,0,1,3.808,23.5A31.8,31.8,0,0,0,115.19,227.857Z" transform="translate(-28.726 0)" fill="#e7e8ff"/>
          <path id="Path_2" data-name="Path 2" d="M163.114,141.23a1.965,1.965,0,0,1,2.694-.689l5.479,3.307a1.971,1.971,0,0,1,.649,2.708,2.121,2.121,0,0,0,.209,2.427,19.763,19.763,0,0,1,3.493,6.438,2.068,2.068,0,0,0,1.913,1.473,1.942,1.942,0,0,1,1.913,2l-.162,6.469a2,2,0,0,1-2.01,1.952,2.132,2.132,0,0,0-1.984,1.421,20.619,20.619,0,0,1-3.82,6.347,2.138,2.138,0,0,0-.328,2.42,2,2,0,0,1-.785,2.692l-5.636,3.164a1.939,1.939,0,0,1-2.663-.759,2.066,2.066,0,0,0-2.194-1.007,19.7,19.7,0,0,1-7.315-.089,2.114,2.114,0,0,0-2.241.949,1.965,1.965,0,0,1-2.694.689l-5.478-3.307a1.972,1.972,0,0,1-.65-2.708,2.121,2.121,0,0,0-.208-2.428,19.767,19.767,0,0,1-3.493-6.437,2.067,2.067,0,0,0-1.913-1.474,1.943,1.943,0,0,1-1.913-2l.162-6.469a2,2,0,0,1,2.01-1.952,2.132,2.132,0,0,0,1.984-1.421,20.655,20.655,0,0,1,3.82-6.347,2.138,2.138,0,0,0,.329-2.42,2,2,0,0,1,.785-2.692l5.636-3.164a1.94,1.94,0,0,1,2.663.759,2.066,2.066,0,0,0,2.194,1.007,19.69,19.69,0,0,1,7.314.089A2.115,2.115,0,0,0,163.114,141.23Zm3.2,26.407a11.209,11.209,0,1,1-3.727-15.541A11.275,11.275,0,0,1,166.315,167.637Z" transform="translate(-67.344 -52.179)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_3" data-name="Path 3" d="M95.536,203.978a.619.619,0,0,1,.84-.211l1.709,1.013a.6.6,0,0,1,.2.83.64.64,0,0,0,.065.744,6.016,6.016,0,0,1,1.09,1.972.643.643,0,0,0,.6.451.6.6,0,0,1,.6.614l-.05,1.982a.618.618,0,0,1-.627.6.664.664,0,0,0-.619.435,6.306,6.306,0,0,1-1.192,1.944.646.646,0,0,0-.1.742.608.608,0,0,1-.245.824l-1.758.969a.611.611,0,0,1-.831-.232.648.648,0,0,0-.684-.308,6.252,6.252,0,0,1-2.282-.027.664.664,0,0,0-.7.291.619.619,0,0,1-.84.211L89,215.8a.6.6,0,0,1-.2-.83.641.641,0,0,0-.065-.744,6.022,6.022,0,0,1-1.09-1.972.643.643,0,0,0-.6-.451.6.6,0,0,1-.6-.614l.051-1.981a.618.618,0,0,1,.627-.6.665.665,0,0,0,.619-.435,6.3,6.3,0,0,1,1.192-1.944.646.646,0,0,0,.1-.742.607.607,0,0,1,.245-.824l1.758-.969a.611.611,0,0,1,.831.232.648.648,0,0,0,.684.308,6.249,6.249,0,0,1,2.281.027A.665.665,0,0,0,95.536,203.978Zm1,8.088a3.553,3.553,0,0,1-4.823,1.212,3.429,3.429,0,0,1-1.163-4.76,3.554,3.554,0,0,1,4.823-1.212A3.429,3.429,0,0,1,96.535,212.067Z" transform="translate(-40.427 -88.172)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_4" data-name="Path 4" d="M449.562,430.892H444.3c4.963-14.888,11.1-21.8,14.6-24.129a.877.877,0,0,1,1.354.878A40.009,40.009,0,0,1,449.562,430.892Z" transform="translate(-243.099 -203.131)" fill="#fff"/>
          <path id="Path_5" data-name="Path 5" d="M458.539,406.48a.409.409,0,0,1,.16.39,39.564,39.564,0,0,1-10.367,22.746H444.07c4.9-14.316,10.835-20.913,14.132-23.115A.284.284,0,0,1,458.539,406.48Zm.612-.922a1.388,1.388,0,0,0-1.563.022c-3.631,2.425-9.829,9.461-14.814,24.414l-.243.728h6.252l.161-.157a40.552,40.552,0,0,0,10.857-23.6A1.513,1.513,0,0,0,459.151,405.557Z" transform="translate(-242.095 -202.408)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_6" data-name="Path 6" d="M451.3,336.972c2.646,20.891-10.192,44.776-17.119,54.311h-7.75c3.693-39.39,16.065-52.994,23.074-55.515A1.372,1.372,0,0,1,451.3,336.972Z" transform="translate(-232.977 -162.968)" fill="#032068"/>
          <path id="Path_7" data-name="Path 7" d="M447.675,362.289l2.618-7.948,1.052.346-6.535,19.838,4.95-2.246.457,1.008-5.878,2.668-5.392,16.368,4.7-2.321.49.993-5.673,2.8-2.618,7.945-1.052-.346,5.317-16.139-2.813-7.009,1.027-.412,2.323,5.789,6.484-19.684-2.087-5.35,1.032-.4Z" transform="translate(-237.713 -173.531)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_8" data-name="Path 8" d="M430.871,305.462c8.289,20.521-2.236,53.418-8.711,67.627h-9.134c.2-44.71,9.835-63.367,15.748-68.248A1.337,1.337,0,0,1,430.871,305.462Z" transform="translate(-225.385 -145.328)" fill="#fff"/>
          <path id="Path_9" data-name="Path 9" d="M428.4,304.54a.785.785,0,0,1,1.232.4c4.084,10.11,3.549,23.334,1.151,35.869a147.27,147.27,0,0,1-9.705,31h-8.221c.14-22.025,2.58-37.64,5.708-48.351C421.724,312.637,425.569,306.878,428.4,304.54Zm2.258-.013a1.891,1.891,0,0,0-2.963-.841c-3.081,2.544-7.008,8.554-10.193,19.46-3.19,10.925-5.653,26.821-5.755,49.213l0,.556H421.79l.148-.324a148,148,0,0,0,9.932-31.573C434.281,328.422,434.864,314.938,430.659,304.527Z" transform="translate(-224.659 -144.6)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_10" data-name="Path 10" d="M431.021,341.658l1.925-11.386,1.092.185-3.866,22.863,4.686-2.942.589.938-5.522,3.467-3.242,19.17,3.519-1.735.49.993-4.236,2.089-2.106,12.454-1.091-.184,3.48-20.585-3.432-7.037,1-.485,2.754,5.647,3.66-21.644-3.527-6.755.981-.513Z" transform="translate(-231.18 -159.9)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_11" data-name="Path 11" d="M452.736,120.694l-1.552-2.028a.407.407,0,0,0-.4-.154l-1.919.367a.419.419,0,0,1-.343-.729l1.508-1.321a.422.422,0,0,0,.137-.391l-.378-2.178a.411.411,0,0,1,.7-.36l1.549,1.674a.406.406,0,0,0,.354.127l1.793-.249a.419.419,0,0,1,.325.731l-1.333,1.186a.424.424,0,0,0-.135.383l.42,2.617A.41.41,0,0,1,452.736,120.694Z" transform="translate(-245.407 -37.281)" fill="#f4ad5e"/>
          <path id="Path_12" data-name="Path 12" d="M216.426,98.009a6.643,6.643,0,0,1,6.643-6.643h75.577a6.643,6.643,0,0,1,6.643,6.643V245.787a6.643,6.643,0,0,1-6.643,6.643H223.069a6.643,6.643,0,0,1-6.643-6.643Z" transform="translate(-114.04 -24.595)" fill="#fff"/>
          <path id="Path_13" data-name="Path 13" d="M215.148,97.286a7.2,7.2,0,0,1,7.2-7.2h75.577a7.2,7.2,0,0,1,7.2,7.2V245.064a7.2,7.2,0,0,1-7.2,7.2H222.345a7.2,7.2,0,0,1-7.2-7.2Zm7.2-6.09a6.089,6.089,0,0,0-6.089,6.09V245.064a6.089,6.089,0,0,0,6.089,6.09h75.577a6.09,6.09,0,0,0,6.09-6.09V97.286a6.09,6.09,0,0,0-6.09-6.09Z" transform="translate(-113.317 -23.872)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_14" data-name="Path 14" d="M274.378,96.765l-2.88-5.4h41.1l-3.386,5.643a5.535,5.535,0,0,1-4.747,2.688h-25.2A5.535,5.535,0,0,1,274.378,96.765Z" transform="translate(-145.231 -24.595)" fill="#032068"/>
          <path id="Path_15" data-name="Path 15" d="M304.883,94.685h-87.25c2.888-2.888,5.647-3.425,6.665-3.332h73.92A8.335,8.335,0,0,1,304.883,94.685Z" transform="translate(-114.724 -24.582)" fill="#032068"/>
          <path id="Path_16" data-name="Path 16" d="M305.638,455.5h-86.6c3,2.935,4.736,3.48,5.794,3.386h75.011A6.337,6.337,0,0,0,305.638,455.5Z" transform="translate(-115.52 -230.822)" fill="#032068"/>
          <path id="Path_17" data-name="Path 17" d="M239.918,139.992a1.661,1.661,0,0,1,1.661-1.661h28.375a1.661,1.661,0,0,1,1.661,1.661v.075a1.66,1.66,0,0,1-1.661,1.66H241.579a1.661,1.661,0,0,1-1.661-1.66Z" transform="translate(-127.345 -51.194)" fill="#f4ad5e"/>
          <path id="Path_18" data-name="Path 18" d="M405.511,109.616v4.528H404.4v-4.528Z" transform="translate(-220.502 -34.931)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_19" data-name="Path 19" d="M398.986,110.921v3.962h-1.107v-3.962Z" transform="translate(-216.807 -35.67)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_20" data-name="Path 20" d="M392.459,113.532v2.83h-1.107v-2.83Z" transform="translate(-213.11 -37.149)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_21" data-name="Path 21" d="M385.933,116.142v1.7h-1.107v-1.7Z" transform="translate(-209.414 -38.627)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_22" data-name="Path 22" d="M240.682,170.651a2.214,2.214,0,0,1,2.214-2.214h63.11a2.214,2.214,0,0,1,2.214,2.214v11.072a2.215,2.215,0,0,1-2.214,2.215H242.9a2.215,2.215,0,0,1-2.214-2.215Zm2.214-1.107a1.107,1.107,0,0,0-1.107,1.107v11.072a1.107,1.107,0,0,0,1.107,1.108h63.11a1.107,1.107,0,0,0,1.107-1.108V170.651a1.107,1.107,0,0,0-1.107-1.107Z" transform="translate(-127.778 -68.244)" fill="#e7e8ff" fill-rule="evenodd"/>
          <path id="Path_23" data-name="Path 23" d="M255.768,178.48a2.492,2.492,0,1,0,2.491,2.491A2.491,2.491,0,0,0,255.768,178.48Zm-3.6,2.491a3.6,3.6,0,1,1,6.438,2.211l1.443,1.566-.814.751-1.435-1.558a3.6,3.6,0,0,1-5.631-2.97Z" transform="translate(-134.284 -73.305)" fill="#f4ad5e" fill-rule="evenodd"/>
          <path id="Path_24" data-name="Path 24" d="M262.383,243.642a1.661,1.661,0,0,1,1.661-1.661h11.072a1.661,1.661,0,0,1,1.66,1.661v11.072a1.661,1.661,0,0,1-1.66,1.661H264.044a1.661,1.661,0,0,1-1.661-1.661Z" transform="translate(-140.068 -109.896)" fill="#f4ad5e"/>
          <path id="Path_25" data-name="Path 25" d="M309.617,244.365a1.107,1.107,0,0,1,1.107-1.107h26.019a1.107,1.107,0,0,1,1.108,1.107v1.107a1.107,1.107,0,0,1-1.108,1.107H310.724a1.107,1.107,0,0,1-1.107-1.107Z" transform="translate(-166.819 -110.619)" fill="#032068"/>
          <path id="Path_26" data-name="Path 26" d="M309.617,263.514a1.107,1.107,0,0,1,1.107-1.107h17.162a1.107,1.107,0,0,1,1.107,1.107v1.107a1.107,1.107,0,0,1-1.107,1.107H310.724a1.107,1.107,0,0,1-1.107-1.107Z" transform="translate(-166.819 -121.464)" fill="#032068"/>
          <path id="Path_27" data-name="Path 27" d="M241.957,228.323a1.661,1.661,0,0,1,1.661-1.661h63.663a1.661,1.661,0,0,1,1.661,1.661v23.251a1.661,1.661,0,0,1-1.661,1.661H243.618a1.661,1.661,0,0,1-1.661-1.661Zm1.661-.554a.554.554,0,0,0-.554.554v23.251a.554.554,0,0,0,.554.554h63.663a.554.554,0,0,0,.554-.554V228.323a.554.554,0,0,0-.554-.554Z" transform="translate(-128.5 -101.22)" fill="#e7e8ff" fill-rule="evenodd"/>
          <path id="Path_28" data-name="Path 28" d="M262.383,321.514a1.66,1.66,0,0,1,1.661-1.66h11.072a1.66,1.66,0,0,1,1.66,1.66v11.072a1.66,1.66,0,0,1-1.66,1.661H264.044a1.66,1.66,0,0,1-1.661-1.661Z" transform="translate(-140.068 -154)" fill="#e7e8ff"/>
          <path id="Path_29" data-name="Path 29" d="M309.617,322.238a1.107,1.107,0,0,1,1.107-1.108h26.019a1.108,1.108,0,0,1,1.108,1.108v1.107a1.107,1.107,0,0,1-1.108,1.107H310.724a1.107,1.107,0,0,1-1.107-1.107Z" transform="translate(-166.819 -154.722)" fill="#e7e8ff"/>
          <path id="Path_30" data-name="Path 30" d="M309.617,341.386a1.107,1.107,0,0,1,1.107-1.107h17.162a1.107,1.107,0,0,1,1.107,1.107v1.107a1.107,1.107,0,0,1-1.107,1.107H310.724a1.107,1.107,0,0,1-1.107-1.107Z" transform="translate(-166.819 -165.567)" fill="#e7e8ff"/>
          <path id="Path_31" data-name="Path 31" d="M241.957,306.2a1.661,1.661,0,0,1,1.661-1.66h63.663a1.661,1.661,0,0,1,1.661,1.66v23.251a1.661,1.661,0,0,1-1.661,1.661H243.618a1.661,1.661,0,0,1-1.661-1.661Zm1.661-.553a.554.554,0,0,0-.554.553v23.251a.554.554,0,0,0,.554.554h63.663a.554.554,0,0,0,.554-.554V306.2a.554.554,0,0,0-.554-.553Z" transform="translate(-128.5 -145.324)" fill="#e7e8ff" fill-rule="evenodd"/>
          <path id="Path_32" data-name="Path 32" d="M262.383,399.387a1.661,1.661,0,0,1,1.661-1.661h11.072a1.661,1.661,0,0,1,1.66,1.661v11.072a1.661,1.661,0,0,1-1.66,1.661H264.044a1.661,1.661,0,0,1-1.661-1.661Z" transform="translate(-140.068 -198.103)" fill="#e7e8ff"/>
          <path id="Path_33" data-name="Path 33" d="M309.617,400.11A1.107,1.107,0,0,1,310.724,399h26.019a1.107,1.107,0,0,1,1.108,1.107v1.107a1.107,1.107,0,0,1-1.108,1.107H310.724a1.107,1.107,0,0,1-1.107-1.107Z" transform="translate(-166.819 -198.826)" fill="#e7e8ff"/>
          <path id="Path_34" data-name="Path 34" d="M309.617,419.259a1.107,1.107,0,0,1,1.107-1.107h17.162a1.107,1.107,0,0,1,1.107,1.107v1.107a1.107,1.107,0,0,1-1.107,1.107H310.724a1.107,1.107,0,0,1-1.107-1.107Z" transform="translate(-166.819 -209.671)" fill="#e7e8ff"/>
          <path id="Path_35" data-name="Path 35" d="M241.957,384.068a1.661,1.661,0,0,1,1.661-1.661h63.663a1.661,1.661,0,0,1,1.661,1.661v23.251a1.661,1.661,0,0,1-1.661,1.66H243.618a1.661,1.661,0,0,1-1.661-1.66Zm1.661-.554a.554.554,0,0,0-.554.554v23.251a.554.554,0,0,0,.554.553h63.663a.554.554,0,0,0,.554-.553V384.068a.554.554,0,0,0-.554-.554Z" transform="translate(-128.5 -189.427)" fill="#e7e8ff" fill-rule="evenodd"/>
          <path id="Path_36" data-name="Path 36" d="M445.39,186.038a19.38,19.38,0,0,1-29.464,16.545l-5.567,1.975a1.42,1.42,0,0,1-1.861-1.644l1.363-6.174a19.377,19.377,0,1,1,35.53-10.7Z" transform="translate(-221.767 -67.239)" fill="#fff"/>
          <path id="Path_37" data-name="Path 37" d="M425.4,166.517a18.9,18.9,0,1,1-9.842,35.044l-.193-.118-5.78,2.05a.946.946,0,0,1-1.241-1.1l1.405-6.37-.111-.167a18.906,18.906,0,0,1,15.76-29.343Zm19.849,18.9a19.849,19.849,0,1,0-36.509,10.795l-1.319,5.98a1.893,1.893,0,0,0,2.481,2.192l5.356-1.9a19.854,19.854,0,0,0,29.99-17.066Z" transform="translate(-221.149 -66.621)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_38" data-name="Path 38" d="M448.7,197.121l-15.719,13.536-7.615-10.471,3.727-2.71,4.672,6.425L445.7,193.629Z" transform="translate(-232.376 -82.512)" fill="#f4ad5e" fill-rule="evenodd"/>
          <path id="Path_39" data-name="Path 39" d="M141.628,201.621c-7.884,5.592-4.57,15.534-3.085,19.387l-.651,7.861c.674,2.475,3.119,7.425,7.506,7.425s6.4-4.225,6.856-6.338l-.686-5.965c5.142-1.864,5.484-9.32,5.142-15.286C156.3,201.621,148.573,196.695,141.628,201.621Z" transform="translate(-68.815 -85.911)" fill="#efcb96"/>
          <path id="Path_40" data-name="Path 40" d="M133.676,210.508l-.683,6.76a15.957,15.957,0,0,1-1.024-3.755c-.341-2.253-5.805-10.891-4.1-17.275,1.366-5.108,5.123-4.882,6.83-4.131,1.025-1.878,7.171-8.262,16.05-7.511s9.9,13.52,2.732,15.4a33.277,33.277,0,0,1-14.684,0,47.161,47.161,0,0,0-1.708,4.882c-1.024,3.38-1.024,4.131-1.366,1.878s-1.707-6.384-3.415-2.253C130.944,207.8,132.651,209.882,133.676,210.508Z" transform="translate(-63.7 -77.361)" fill="#032068"/>
          <path id="Path_41" data-name="Path 41" d="M153.127,255.489c2.35,1.388,5.983.578,7.506,0l.327,2.429C157.565,258.75,154.323,256.646,153.127,255.489Z" transform="translate(-78.191 -117.546)" fill="#032068"/>
          <path id="Path_42" data-name="Path 42" d="M286.439,271.371a31.626,31.626,0,0,1-1.2,4.882l5.052,4.394a51.168,51.168,0,0,1,2.887-4.638c.962-1.221,4.33-2.2,4.811-5.371.385-2.539-2.245-4.638-3.608-5.371.641-1.221,2.068-4,2.646-5.371.722-1.709-.481-2.441-1.443-1.953-.77.391-4.49,7-6.254,10.253-.241-.081-.722-.537-.722-1.709a1.722,1.722,0,0,0-1.443-1.709C285.717,267.221,286.439,270.395,286.439,271.371Z" transform="translate(-153.011 -118.853)" fill="#efcb96"/>
          <path id="Path_43" data-name="Path 43" d="M88.909,267.311c5.337,0,7.784-3.344,8.339-5.016,4.448,1.115,11.954,3.065,17.236,8.36,5.838,5.852,11.119,21.18,11.953,20.9s14.177-14.77,17.235-16.163,9.729,5.295,9.729,6.41-3.892,16.163-12.787,31.77c-7.116,12.485-18.718,7.617-23.629,3.623.278,4.366.945,15.049,1.39,22.852s-4.077,9.382-6.394,9.2H58.053c-7.116,0-6.672-9.475-5.56-14.213-6.671-3.9-14.733-11.7-14.733-20.065-.556-14.213,8.062-27.311,14.733-39.573,5.337-9.81,20.942-12.82,28.076-13.1C81.126,263.967,83.572,267.311,88.909,267.311Z" transform="translate(-12.838 -121.401)" fill="#f4ad5e"/>
          <path id="Path_44" data-name="Path 44" d="M89.391,438.385c-3.781-.669-13.807-5.852-18.347-8.36l-.556,3.065C76.27,435.989,85.5,437.828,89.391,438.385Z" transform="translate(-31.388 -216.395)" fill="#032068"/>
          <path id="Path_45" data-name="Path 45" d="M16.221,383.071a1,1,0,0,1,.893-1.093l69.685-7a1,1,0,0,1,1.091.894l2.557,25.758a1,1,0,0,1-.894,1.093l-69.685,7a1,1,0,0,1-1.091-.894Z" transform="translate(-0.651 -185.218)" fill="#fff"/>
          <path id="Path_46" data-name="Path 46" d="M15.074,382.468a1.5,1.5,0,0,1,1.34-1.64l69.686-7a1.5,1.5,0,0,1,1.636,1.341l2.557,25.758a1.5,1.5,0,0,1-1.34,1.64l-69.686,7a1.5,1.5,0,0,1-1.636-1.341Zm1.439-.646a.5.5,0,0,0-.447.546l2.557,25.758a.5.5,0,0,0,.545.447l69.686-7a.5.5,0,0,0,.447-.546l-2.557-25.758a.5.5,0,0,0-.545-.447Z" transform="translate(0 -184.565)" fill="#e7e8ff" fill-rule="evenodd"/>
          <path id="Path_47" data-name="Path 47" d="M90.09,397.883a1,1,0,0,1,.893-1.092l28.728-2.882a1,1,0,0,1,1.091.893l.162,1.63a1,1,0,0,1-.893,1.092l-28.728,2.882a1,1,0,0,1-1.091-.893Z" transform="translate(-42.487 -195.938)" fill="#032068"/>
          <path id="Path_48" data-name="Path 48" d="M92.174,420.023a1,1,0,0,1,.893-1.092l19.094-1.916a1,1,0,0,1,1.09.893l.162,1.631a1,1,0,0,1-.893,1.092l-19.094,1.916a1,1,0,0,1-1.091-.893Z" transform="translate(-43.667 -209.024)" fill="#032068"/>
          <path id="Path_49" data-name="Path 49" d="M38.687,404.158a1.5,1.5,0,0,1,1.34-1.64l12.683-1.272a1.5,1.5,0,0,1,1.636,1.342L55.61,415.3a1.5,1.5,0,0,1-1.34,1.64l-12.684,1.272a1.5,1.5,0,0,1-1.636-1.342Z" transform="translate(-13.374 -200.091)" fill="#f4ad5e"/>
          <path id="Path_50" data-name="Path 50" d="M120.92,428.774,116,435.915c3.882,2.909,3.882,5.025,8.282,6.083s6.729-5.289,6.729-6.083c0-.634-2.415-2.732-3.624-3.7h3.624c-.431-1.058-1.553-3.226-2.588-3.438-1.294-.265-4.918,1.058-5.435,1.058A6.756,6.756,0,0,1,120.92,428.774Z" transform="translate(-57.165 -215.667)" fill="#efcb96"/>
          <path id="Path_51" data-name="Path 51" d="M42.894,386.832c-5.337-5.127-5.745-13.841-5.282-17.557L57.9,367.046c8.006,7.357,16.494,19.6,19.737,24.8.278,1.672-5,10.311-7.228,10.869C59.573,398.816,49.566,393.242,42.894,386.832Z" transform="translate(-12.689 -180.727)" fill="#f4ad5e"/>
          <path id="Path_52" data-name="Path 52" d="M86.2,356.878l6.95-9.754-4.448,11.7c4.67,5.574,11.583,16.814,14.455,21.737-3.336-4.9-15.474-19.136-21.127-25.639Z" transform="translate(-37.924 -169.444)" fill="#032068"/>
          <path id="Path_53" data-name="Path 53" d="M213.439,328.487c1.334,6.242,2.595,20.065,3.058,26.2l2.5,1.672C217.22,341.641,214.551,331.645,213.439,328.487Z" transform="translate(-112.349 -158.889)" fill="#032068"/>
          <path id="Path_54" data-name="Path 54" d="M38.418,363.19c2.131,2.973,5.838,11.091,3.614,19.787C39.623,380.933,35.527,374.114,38.418,363.19Z" transform="translate(-12.662 -178.543)" fill="#032068"/>
          <path id="Path_55" data-name="Path 55" d="M247,383.656c-3.614-.929-12.565-1.393-19.459,4.18C231.527,390.251,241,392.8,247,383.656Z" transform="translate(-120.337 -189.812)" fill="#032068"/>
        </g>
      </svg>
      <h1>Welcome!</h1>
      <p>Register to commence your journey</p>
      <form @submit.prevent="handleSubmit">
        <div class="input-group row">
          <input
              type="text"
              v-model="firstName"
              placeholder="First Name"
              :class="{'valid': isFirstNameValid, 'invalid': !isFirstNameValid && firstName.length > 0}"
              required>
          <input
              type="text"
              v-model="lastName"
              placeholder="Last Name"
              :class="{'valid': isLastNameValid, 'invalid': !isLastNameValid && lastName.length > 0}"
              required>
        </div>
        <div class="input-group">
          <input
              type="text"
              v-model="username"
              placeholder="Username"
              :class="{'valid': isUsernameValid, 'invalid': !isUsernameValid && username.length > 0}"
              required>
        </div>
        <div class="input-group">
          <input
              type="email"
              v-model="email"
              placeholder="Mail Address"
              :class="{'valid': isEmailValid, 'invalid': !isEmailValid && email.length > 0}"
              required>
        </div>
        <div class="input-group">
          <select v-model="country" @change="updatePhonePlaceholder" required>
            <option value="" disabled selected>Your Country</option>
            <option v-for="country in countries" :value="country.code">{{ country.name }}</option>
          </select>
        </div>
        <div class="input-group">
          <div class="phone-input">
            <span class="country-code" :style="{ height: phoneNumberHeight + 'px' }">{{ selectedCountryCode }}</span>
            <input
                type="tel"
                v-model="phoneNumber"
                :placeholder="phonePlaceholder"
                ref="phoneNumberField"
                :class="{'valid': isPhoneNumberValid, 'invalid': !isPhoneNumberValid && phoneNumber.length > 0}"
                required>
          </div>
        </div>
        <div class="input-group">
          <div class="password-wrapper">
            <input
                :type="passwordFieldType"
                v-model="password"
                @input="checkPasswordStrength"
                placeholder="Password"
                :class="{
                  'valid': isPasswordValid && password.length >= 8,
                  'invalid': !isPasswordValid && password.length > 0
                }"
                required>
            <span class="password-strength" :class="passwordStrength.class">{{ passwordStrength.text }}</span>
            <span class="toggle-password" @click="togglePasswordVisibility">
              <i :class="passwordVisibilityIcon"></i>
            </span>
          </div>
        </div>
        <div class="input-group">
          <div class="password-confirm">
            <input
                :type="passwordFieldType"
                v-model="confirmPassword"
                placeholder="Confirm Password"
                :class="{
                  'valid': isConfirmPasswordValid && confirmPassword.length > 0,
                  'invalid': !isConfirmPasswordValid && password.length >= 8,
                  'no-color': password.length === 0
                }"
                required>
          </div>
        </div>
        <div class="terms-container">
          <input type="checkbox" id="terms" v-model="terms" required>
          <label for="terms">I agree to the <a href="#">Terms & Privacy Policy</a>.</label>
        </div>
        <button type="submit">Register</button>
      </form>
      <div class="footer-links">
        <p>Already have an account? <router-link to="/login">Login</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      country: '',
      phoneNumber: '',
      phonePlaceholder: 'Phone Number',
      password: '',
      confirmPassword: '',
      terms: false,
      notificationMessage: '',
      progressWidth: 0,
      progressInterval: null,
      notificationClass: '',
      notificationIcon: '',
      countries: [
        { code: '+93', name: 'Afghanistan', minLength: 9, maxLength: 9 },
        { code: '+355', name: 'Albania', minLength: 9, maxLength: 9 },
        { code: '+213', name: 'Algeria', minLength: 9, maxLength: 9 },
        { code: '+54', name: 'Argentina', minLength: 10, maxLength: 10 },
        { code: '+61', name: 'Australia', minLength: 9, maxLength: 9 },
        { code: '+43', name: 'Austria', minLength: 10, maxLength: 10 },
        { code: '+880', name: 'Bangladesh', minLength: 10, maxLength: 10 },
        { code: '+32', name: 'Belgium', minLength: 9, maxLength: 9 },
        { code: '+55', name: 'Brazil', minLength: 10, maxLength: 11 },
        { code: '+359', name: 'Bulgaria', minLength: 9, maxLength: 9 },
        { code: '+1', name: 'Canada', minLength: 10, maxLength: 10 },
        { code: '+86', name: 'China', minLength: 11, maxLength: 11 },
        { code: '+57', name: 'Colombia', minLength: 10, maxLength: 10 },
        { code: '+506', name: 'Costa Rica', minLength: 8, maxLength: 8 },
        { code: '+385', name: 'Croatia', minLength: 9, maxLength: 9 },
        { code: '+420', name: 'Czech Republic', minLength: 9, maxLength: 9 },
        { code: '+45', name: 'Denmark', minLength: 8, maxLength: 8 },
        { code: '+20', name: 'Egypt', minLength: 10, maxLength: 10 },
        { code: '+372', name: 'Estonia', minLength: 7, maxLength: 7 },
        { code: '+358', name: 'Finland', minLength: 9, maxLength: 9 },
        { code: '+33', name: 'France', minLength: 9, maxLength: 9 },
        { code: '+49', name: 'Germany', minLength: 10, maxLength: 11 },
        { code: '+30', name: 'Greece', minLength: 10, maxLength: 10 },
        { code: '+852', name: 'Hong Kong', minLength: 8, maxLength: 8 },
        { code: '+36', name: 'Hungary', minLength: 9, maxLength: 9 },
        { code: '+354', name: 'Iceland', minLength: 7, maxLength: 7 },
        { code: '+91', name: 'India', minLength: 10, maxLength: 10 },
        { code: '+62', name: 'Indonesia', minLength: 10, maxLength: 11 },
        { code: '+98', name: 'Iran', minLength: 10, maxLength: 10 },
        { code: '+964', name: 'Iraq', minLength: 10, maxLength: 10 },
        { code: '+353', name: 'Ireland', minLength: 9, maxLength: 9 },
        { code: '+972', name: 'Israel', minLength: 9, maxLength: 10 },
        { code: '+39', name: 'Italy', minLength: 10, maxLength: 10 },
        { code: '+81', name: 'Japan', minLength: 10, maxLength: 10 },
        { code: '+254', name: 'Kenya', minLength: 10, maxLength: 10 },
        { code: '+82', name: 'South Korea', minLength: 10, maxLength: 10 },
        { code: '+965', name: 'Kuwait', minLength: 8, maxLength: 8 },
        { code: '+371', name: 'Latvia', minLength: 8, maxLength: 8 },
        { code: '+961', name: 'Lebanon', minLength: 7, maxLength: 7 },
        { code: '+370', name: 'Lithuania', minLength: 8, maxLength: 8 },
        { code: '+60', name: 'Malaysia', minLength: 9, maxLength: 10 },
        { code: '+52', name: 'Mexico', minLength: 10, maxLength: 10 },
        { code: '+31', name: 'Netherlands', minLength: 9, maxLength: 9 },
        { code: '+64', name: 'New Zealand', minLength: 8, maxLength: 10 },
        { code: '+234', name: 'Nigeria', minLength: 10, maxLength: 10 },
        { code: '+47', name: 'Norway', minLength: 8, maxLength: 8 },
        { code: '+92', name: 'Pakistan', minLength: 10, maxLength: 10 },
        { code: '+51', name: 'Peru', minLength: 9, maxLength: 9 },
        { code: '+63', name: 'Philippines', minLength: 10, maxLength: 10 },
        { code: '+48', name: 'Poland', minLength: 9, maxLength: 9 },
        { code: '+351', name: 'Portugal', minLength: 9, maxLength: 9 },
        { code: '+974', name: 'Qatar', minLength: 8, maxLength: 8 },
        { code: '+40', name: 'Romania', minLength: 9, maxLength: 10 },
        { code: '+7', name: 'Russia', minLength: 10, maxLength: 10 },
        { code: '+966', name: 'Saudi Arabia', minLength: 9, maxLength: 9 },
        { code: '+65', name: 'Singapore', minLength: 8, maxLength: 8 },
        { code: '+421', name: 'Slovakia', minLength: 9, maxLength: 9 },
        { code: '+386', name: 'Slovenia', minLength: 9, maxLength: 9 },
        { code: '+27', name: 'South Africa', minLength: 9, maxLength: 9 },
        { code: '+34', name: 'Spain', minLength: 9, maxLength: 9 },
        { code: '+94', name: 'Sri Lanka', minLength: 9, maxLength: 9 },
        { code: '+46', name: 'Sweden', minLength: 9, maxLength: 10 },
        { code: '+41', name: 'Switzerland', minLength: 9, maxLength: 10 },
        { code: '+886', name: 'Taiwan', minLength: 9, maxLength: 10 },
        { code: '+66', name: 'Thailand', minLength: 9, maxLength: 10 },
        { code: '+90', name: 'Turkey', minLength: 10, maxLength: 10 },
        { code: '+380', name: 'Ukraine', minLength: 9, maxLength: 9 },
        { code: '+971', name: 'United Arab Emirates', minLength: 9, maxLength: 9 },
        { code: '+44', name: 'United Kingdom', minLength: 10, maxLength: 10 },
        { code: '+1', name: 'United States', minLength: 10, maxLength: 10 },
        { code: '+58', name: 'Venezuela', minLength: 10, maxLength: 10 },
        { code: '+84', name: 'Vietnam', minLength: 9, maxLength: 10 }
      ],
      passwordStrength: {
        text: '',
        class: ''
      },
      phoneNumberHeight: 0,
      showPassword: false,
    };
  },
  computed: {
    selectedCountryCode() {
      const country = this.countries.find(c => c.code === this.country);
      return country ? country.code : '';
    },
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordVisibilityIcon() {
      return this.showPassword ? 'fa fa-eye-slash' : 'fa fa-eye';
    },
    isFirstNameValid() {
      return /^[A-Za-z\s]+$/.test(this.firstName);
    },
    isLastNameValid() {
      return /^[A-Za-z\s]+$/.test(this.lastName);
    },
    isUsernameValid() {
      return /^[a-zA-Z0-9]{6,}$/.test(this.username);
    },
    isEmailValid() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
    },
    isPhoneNumberValid() {
      const country = this.countries.find(c => c.code === this.country);
      return country && this.phoneNumber.length >= country.minLength && this.phoneNumber.length <= country.maxLength;
    },
    isPasswordValid() {
      return this.password.length >= 8 && /[a-z]/.test(this.password) && /[A-Z]/.test(this.password) && /\d/.test(this.password) && /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    },
    isConfirmPasswordValid() {
      return this.password.length > 0 && this.password === this.confirmPassword;
    }
  },
  methods: {
    handleSubmit() {
      if (!this.isFirstNameValid) {
        this.showNotification('Invalid first name', 'error', '✖');
        return;
      }
      if (!this.isLastNameValid) {
        this.showNotification('Invalid last name', 'error', '✖');
        return;
      }
      if (!this.isUsernameValid) {
        this.showNotification('Invalid username', 'error', '✖');
        return;
      }
      if (!this.isEmailValid) {
        this.showNotification('Invalid email address', 'error', '✖');
        return;
      }
      if (!this.country) {
        this.showNotification('Please select a country', 'error', '✖');
        return;
      }
      if (!this.isPhoneNumberValid) {
        this.showNotification('Invalid phone number', 'error', '✖');
        return;
      }
      if (!this.isPasswordValid) {
        this.showNotification('Invalid password', 'error', '✖');
        return;
      }
      if (!this.isConfirmPasswordValid) {
        this.showNotification('Passwords do not match', 'error', '✖');
        return;
      }
      if (!this.terms) {
        this.showNotification('Accept Terms & Policy', 'error', '✖');
        return;
      }
      this.showNotification('Registration successful', 'success', '✔');
      // Submit the form or handle the registration logic here
    },
    checkPasswordStrength() {
      const password = this.password;
      if (password.length < 8) {
        this.passwordStrength = { text: 'Too short', class: 'weak' };
      } else if (/^[a-z]+$/.test(password)) {
        this.passwordStrength = { text: 'Low', class: 'low' };
      } else if (/^[a-z0-9]+$/.test(password)) {
        this.passwordStrength = { text: 'Normal', class: 'normal' };
      } else if (/^[a-zA-Z0-9]+$/.test(password)) {
        this.passwordStrength = { text: 'Good', class: 'good' };
      } else {
        this.passwordStrength = { text: 'Perfect', class: 'perfect' };
      }
    },
    updatePhonePlaceholder() {
      const country = this.countries.find(c => c.code === this.country);
      if (country) {
        this.phonePlaceholder = `${country.name} Phone Number`;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    showNotification(message, type, icon) {
      this.notificationMessage = message;
      this.notificationClass = type;
      this.notificationIcon = icon;
      this.startProgressBar();
      setTimeout(() => {
        this.notificationMessage = '';
      }, 3500);
    },
    startProgressBar() {
      this.progressWidth = 100;
      if (this.progressInterval) clearInterval(this.progressInterval);
      this.progressInterval = setInterval(() => {
        if (this.progressWidth > 0) {
          this.progressWidth -= 0.5;
        } else {
          clearInterval(this.progressInterval);
        }
      }, 25);
    },
    closeNotification() {
      this.notificationMessage = '';
    },
    resetNotification() {
      this.progressWidth = 0;
      clearInterval(this.progressInterval);
    }
  },
  mounted() {
    this.phoneNumberHeight = this.$refs.phoneNumberField.offsetHeight;
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

body, h1, p, input, select, button, label, a {
  font-family: 'Nunito', sans-serif;
}

.register-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: start;
}

.register-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.svg-container {
  margin-top: 10px;
}

h1 {
  margin-top: 5px;
  margin-bottom: -15px;
  font-weight: 700;
  font-size: 1.5rem;
}

p {
  margin-bottom: 10px;
  color: #6c757d;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group.row {
  display: flex;
  justify-content: space-between;
}

.input-group.row input {
  width: 49%;
}

.input-group input,
.input-group select {
  height: 2.7rem;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: -8px;
}

.input-group input:focus,
.input-group select:focus {
  outline: none;
  border-color: #6c63ff;
}

.valid {
  border-color: green !important;
}

.invalid {
  border-color: red !important;
}

.no-color {
  border-color: #ced4da !important;
}

.phone-input {
  display: flex;
  align-items: center;
  margin-top: -8px;
}

.country-code {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 4px 0 0 4px;
  font-size: 0.95rem;
  font-family: 'Nunito', sans-serif;
  height: 100%;
  min-width: 55px;
  margin-top: 8px;
}

.phone-input input {
  font-family: 'Nunito', sans-serif;
  border-radius: 0 4px 4px 0;
  height: 100%;
}

.password-wrapper {
  position: relative;
  margin-top: -7px;
}

.password-strength {
  margin-top: 5px;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.9rem;
}

.toggle-password {
  margin-top: 5px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-strength.weak {
  color: red;
}

.password-strength.low {
  color: red;
}

.password-strength.normal {
  color: orange;
}

.password-strength.good {
  color: lightgreen;
}

.password-strength.perfect {
  color: green;
}

.terms-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.terms-container input[type="checkbox"] {
  margin-right: 0.5rem;
}

.terms-container label {
  font-size: 1rem;
  color: #6c757d;
}

.terms-container a {
  color: #6c63ff;
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #6c63ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #5753d6;
}

.footer-links {
  margin-top: 1.5rem;
  color: #6c757d;
}

.footer-links a {
  color: #6c63ff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.notification-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 370px;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  transition: opacity 1s ease;
  cursor: pointer;
}

.notification-popup.error {
  background-color: #f44336;
  color: #fff;
}

.notification-popup.success {
  background-color: #4caf50;
  color: #fff;
}

.notification-icon {
  font-size: 1rem;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.5s linear;
}

/* Animation for fading */
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}
</style>
