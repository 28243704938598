<template>
  <div class="notifications-container">
    <h2>Notifications</h2>
    <ul class="notifications-list">
      <li v-for="notification in notifications" :key="notification.id" class="notification-item">
        <p>{{ notification.message }}</p>
        <span class="notification-date">{{ notification.date }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NotificationsView',
  data() {
    return {
      notifications: this.generateRandomNotifications()
    };
  },
  methods: {
    generateRandomNotifications() {
      const notifications = [];
      for (let i = 1; i <= 20; i++) {
        notifications.push({
          id: i,
          message: `This is notification message ${i}`,
          date: new Date().toLocaleDateString()
        });
      }
      return notifications;
    }
  }
}
</script>

<style scoped>
.notifications-container {
  padding: 20px;
  text-align: left;
  max-width: 600px;
  margin-bottom: 65px;
margin-top: 45px;
}

h2 {
  text-align: center;
  color: #e09f3e;
  margin-bottom: 20px;
}

.notifications-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  padding: 15px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-item p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.notification-date {
  display: block;
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  text-align: right;
}
</style>
