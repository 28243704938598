<template>
  <div class="login-container">
    <transition name="fade" @after-leave="resetNotification">
      <div v-if="notificationMessage" class="notification-popup" :class="notificationClass" @click="closeNotification">
        <span class="notification-icon">{{ notificationIcon }}</span>
        <span>{{ notificationMessage }}</span>
        <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
      </div>
    </transition>
    <div class="login-card">
      <div class="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="221.515" height="180.368" viewBox="0 0 221.515 180.368">
        <g id="Group_11" data-name="Group 11" transform="translate(-14.775 -60.082)">
          <path id="Path_56" data-name="Path 56" d="M66.386,239.886H202.375a12.4,12.4,0,0,0,9.594-20.264,12.4,12.4,0,0,1-1.669-13.055L215.82,194.6A33.458,33.458,0,0,0,193.037,148l-.887-.207a34.2,34.2,0,0,1-26.433-33.307v-7.566A43.734,43.734,0,0,0,131.7,64.282l-13.849-3.157a41.152,41.152,0,0,0-50.17,36.881l-.33,4.177a31.252,31.252,0,0,1-11.226,21.61L36.275,140.23a8.875,8.875,0,0,0-.314,13.4,8.876,8.876,0,0,1-.533,13.577L24.1,175.995a20.926,20.926,0,0,0-4.49,28.284L31.072,221.17A42.676,42.676,0,0,0,66.386,239.886Z" transform="translate(-0.661 0)" fill="#e7e8ff"/>
          <path id="Path_57" data-name="Path 57" d="M143.348,101.153a6.764,6.764,0,0,1,6.764-6.764h76.949a6.764,6.764,0,0,1,6.764,6.764V251.615a6.764,6.764,0,0,1-6.764,6.764H150.112a6.764,6.764,0,0,1-6.764-6.764Z" transform="translate(-69.389 -18.515)" fill="#fff"/>
          <path id="Path_58" data-name="Path 58" d="M142.123,100.468a7.315,7.315,0,0,1,7.327-7.3H226.4a7.315,7.315,0,0,1,7.327,7.3V250.437a7.315,7.315,0,0,1-7.327,7.3H149.45a7.315,7.315,0,0,1-7.327-7.3Zm7.327-6.18a6.19,6.19,0,0,0-6.2,6.18V250.437a6.19,6.19,0,0,0,6.2,6.18H226.4a6.19,6.19,0,0,0,6.2-6.18V100.468a6.19,6.19,0,0,0-6.2-6.18Z" transform="translate(-68.728 -17.854)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_59" data-name="Path 59" d="M202.085,388.225a7.327,7.327,0,1,1-7.327-7.327A7.327,7.327,0,0,1,202.085,388.225Z" transform="translate(-93.179 -173.14)" fill="#f4ad5e"/>
          <path id="Path_60" data-name="Path 60" d="M241.112,255.9a1.127,1.127,0,1,1-1.127-1.127A1.127,1.127,0,0,1,241.112,255.9Z" transform="translate(-120.934 -105.073)" fill="#e7e8ff"/>
          <path id="Path_61" data-name="Path 61" d="M228.868,255.9a1.127,1.127,0,1,1-1.127-1.127A1.127,1.127,0,0,1,228.868,255.9Z" transform="translate(-114.326 -105.073)" fill="#032068"/>
          <path id="Path_62" data-name="Path 62" d="M253.358,255.9a1.127,1.127,0,1,1-1.127-1.127A1.127,1.127,0,0,1,253.358,255.9Z" transform="translate(-127.543 -105.073)" fill="#e7e8ff"/>
          <path id="Path_63" data-name="Path 63" d="M199.106,99.887l-2.932-5.5H238.02l-3.448,5.746a5.636,5.636,0,0,1-4.833,2.736H204.08A5.636,5.636,0,0,1,199.106,99.887Z" transform="translate(-97.898 -18.515)" fill="#032068"/>
          <path id="Path_64" data-name="Path 64" d="M233.341,97.77H144.506c2.94-2.94,5.749-3.487,6.786-3.393h75.263A8.487,8.487,0,0,1,233.341,97.77Z" transform="translate(-70.014 -18.503)" fill="#032068"/>
          <path id="Path_65" data-name="Path 65" d="M233.969,443.66H145.8c3.056,1.921,4.822,2.278,5.9,2.217H228.07C231.831,445.877,233.185,444.4,233.969,443.66Z" transform="translate(-70.711 -207.012)" fill="#032068"/>
          <path id="Path_66" data-name="Path 66" d="M171.512,139.576a1.127,1.127,0,0,1,1.127-1.127H192.93a1.127,1.127,0,0,1,1.127,1.127V140.7a1.127,1.127,0,0,1-1.127,1.127H172.639a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-84.589 -42.294)" fill="#f4ad5e"/>
          <path id="Path_67" data-name="Path 67" d="M175.184,295.086a1.127,1.127,0,0,1,1.127-1.127h31a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127h-31a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-86.57 -126.22)" fill="#032068"/>
          <path id="Path_68" data-name="Path 68" d="M232.736,383.249a1.128,1.128,0,0,1,1.127-1.127h26.492a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H233.863a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-117.63 -173.801)" fill="#032068"/>
          <path id="Path_69" data-name="Path 69" d="M232.736,401.617a1.127,1.127,0,0,1,1.127-1.127h17.473a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H233.863a1.128,1.128,0,0,1-1.127-1.127Z" transform="translate(-117.63 -183.714)" fill="#032068"/>
          <path id="Path_70" data-name="Path 70" d="M175.184,323.249a1.127,1.127,0,0,1,1.127-1.127h59.183a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H176.311a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-86.57 -141.42)" fill="#e7e8ff"/>
          <path id="Path_71" data-name="Path 71" d="M175.184,344.066a1.127,1.127,0,0,1,1.127-1.127h59.183a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H176.311a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-86.57 -152.654)" fill="#e7e8ff"/>
          <path id="Path_72" data-name="Path 72" d="M192.328,183.658a1.127,1.127,0,0,1,1.127-1.127h31a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127h-31a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-95.823 -66.084)" fill="#f4ad5e"/>
          <path id="Path_73" data-name="Path 73" d="M192.328,209.372a1.127,1.127,0,0,1,1.127-1.127H208.11a1.127,1.127,0,0,1,1.127,1.127V210.5a1.128,1.128,0,0,1-1.127,1.127H193.455a1.128,1.128,0,0,1-1.127-1.127Z" transform="translate(-95.823 -79.962)" fill="#f4ad5e"/>
          <path id="Path_74" data-name="Path 74" d="M172.736,371.568a1.691,1.691,0,0,1,1.691-1.691h60.31a1.691,1.691,0,0,1,1.691,1.691V391.86a1.691,1.691,0,0,1-1.691,1.691h-60.31a1.691,1.691,0,0,1-1.691-1.691Zm1.691-.564a.564.564,0,0,0-.564.564V391.86a.564.564,0,0,0,.564.563h60.31a.564.564,0,0,0,.564-.563V371.568a.564.564,0,0,0-.564-.564Z" transform="translate(-85.249 -167.192)" fill="#e7e8ff" fill-rule="evenodd"/>
          <path id="Path_75" data-name="Path 75" d="M172.736,158.507a1.691,1.691,0,0,1,1.691-1.691h60.31a1.691,1.691,0,0,1,1.691,1.691v50.165a1.691,1.691,0,0,1-1.691,1.691h-60.31a1.691,1.691,0,0,1-1.691-1.691Zm1.691-.564a.564.564,0,0,0-.564.564v50.165a.564.564,0,0,0,.564.564h60.31a.564.564,0,0,0,.564-.564V158.507a.564.564,0,0,0-.564-.564Z" transform="translate(-85.249 -52.206)" fill="#e7e8ff" fill-rule="evenodd"/>
          <path id="Path_76" data-name="Path 76" d="M324.781,111.895v4.61h-1.127v-4.61Z" transform="translate(-166.697 -27.963)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_77" data-name="Path 77" d="M318.522,113.146v4.034h-1.127v-4.034Z" transform="translate(-163.32 -28.638)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_78" data-name="Path 78" d="M312.262,115.65v2.882h-1.127V115.65Z" transform="translate(-159.941 -29.99)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_79" data-name="Path 79" d="M306,118.154v1.729h-1.127v-1.729Z" transform="translate(-156.563 -31.341)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_80" data-name="Path 80" d="M386.779,229.539c7.959,5.656,4.614,15.711,3.115,19.607l.657,7.951c-.68,2.5-3.149,7.509-7.578,7.509s-6.46-4.273-6.921-6.41l.692-6.033c-5.191-1.885-5.537-9.426-5.191-15.46C371.963,229.539,379.768,224.557,386.779,229.539Z" transform="translate(-192.496 -90.368)" fill="#efcb96"/>
          <path id="Path_81" data-name="Path 81" d="M338.266,296.241c-5.2,0-7.637-3.207-8.207-4.81-4.83.693-14.667,4.77-19.873,12.616-6.211,9.36-11.041,34.321-12.766,40.214-1.38,4.715-6.708,15.728-9.583,20.7h69.92c5.243.344,16.687.481,20.517-1.718,4.787-2.749,9.233-16.836,0-44.666-7.386-22.264-24.735-27.372-32.485-27.143C345.447,293.034,343.464,296.241,338.266,296.241Z" transform="translate(-147.368 -124.852)" fill="#f4ad5e"/>
          <path id="Path_82" data-name="Path 82" d="M324.9,372.318l-7.246,36.4h3.105Z" transform="translate(-163.457 -168.51)" fill="#032068"/>
          <path id="Path_83" data-name="Path 83" d="M451.488,434.523c8.244-10.1,16.96-13.7,20.287-14.237,0,3.236-1.61,9.383-4.508,12.3C464.949,434.911,455.781,434.847,451.488,434.523Z" transform="translate(-235.688 -194.397)" fill="#032068"/>
          <path id="Path_84" data-name="Path 84" d="M398.874,366.334c2.394,1.031,20.175,9.391,28.382,13.4-1.026-1.718-5.129-4.466-6.155-5.5s-4.787-13.056-4.787-11.338,2.052,9.621,1.026,9.621S401.952,366.334,398.874,366.334Z" transform="translate(-207.293 -163.336)" fill="#032068"/>
          <path id="Path_85" data-name="Path 85" d="M383.274,392.2c14.5,19.516,26.33,26.456,30.433,27.487h-7.18A92.8,92.8,0,0,1,383.274,392.2Z" transform="translate(-198.874 -179.242)" fill="#032068"/>
          <path id="Path_86" data-name="Path 86" d="M357.669,335.48l2.626,25.73a.5.5,0,0,1-.578.539l-12.341-2.226a.5.5,0,0,1-.4-.416l-3.619-24.461a.5.5,0,0,1,.523-.568l13.334.957A.5.5,0,0,1,357.669,335.48Z" transform="translate(-177.327 -147.872)" fill="#fff"/>
          <path id="Path_87" data-name="Path 87" d="M343.334,333a.99.99,0,0,0-1.047,1.136l3.619,24.461a.991.991,0,0,0,.8.831l12.341,2.226a.99.99,0,0,0,1.157-1.078l-2.627-25.73a.99.99,0,0,0-.912-.888Zm-.07.99,13.334.957,2.626,25.73-12.341-2.226Z" transform="translate(-176.748 -147.29)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_88" data-name="Path 88" d="M381.916,239.412l.69,6.837a16.17,16.17,0,0,0,1.034-3.8c.345-2.279,5.861-11.015,4.137-17.472-1.379-5.166-5.172-4.938-6.9-4.178-1.034-1.9-7.24-8.356-16.2-7.6s-10,13.674-2.758,15.572a33.526,33.526,0,0,0,14.825,0,47.6,47.6,0,0,1,1.724,4.938c1.034,3.418,1.034,4.178,1.379,1.9s1.724-6.457,3.448-2.279C384.675,236.677,382.951,238.779,381.916,239.412Z" transform="translate(-184.769 -82.605)" fill="#032068"/>
          <path id="Path_89" data-name="Path 89" d="M390.813,280.748c-2.372,1.4-6.04.585-7.578,0l-.33,2.456C386.332,284.047,389.6,281.918,390.813,280.748Z" transform="translate(-198.674 -119.091)" fill="#032068"/>
          <path id="Path_90" data-name="Path 90" d="M373.263,355.441a9.222,9.222,0,0,0,3.078,4.123l-4.445,6.872c-1.368-1.374-3.078-1.374-6.839-3.092s-4.787-7.9-3.762-11.338c.821-2.749,5.585-.687,7.523.687l-.342-3.78C370.869,349.944,372.238,352.693,373.263,355.441Z" transform="translate(-186.81 -155.878)" fill="#efcb96"/>
          <path id="Path_91" data-name="Path 91" d="M16,284.066a1.127,1.127,0,0,1,1.127-1.127H61.656a1.127,1.127,0,0,1,1.127,1.127v21.418a1.127,1.127,0,0,1-1.127,1.127H17.127A1.127,1.127,0,0,1,16,305.485Z" transform="translate(-0.661 -120.273)" fill="#fff"/>
          <path id="Path_92" data-name="Path 92" d="M14.775,283.405a1.691,1.691,0,0,1,1.691-1.691H60.995a1.691,1.691,0,0,1,1.691,1.691v21.418a1.691,1.691,0,0,1-1.691,1.691H16.466a1.691,1.691,0,0,1-1.691-1.691Zm1.691-.564a.564.564,0,0,0-.564.564v21.418a.564.564,0,0,0,.564.564H60.995a.564.564,0,0,0,.563-.564V283.405a.564.564,0,0,0-.563-.564Z" transform="translate(0 -119.612)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_93" data-name="Path 93" d="M31.918,296.311a1.127,1.127,0,0,1,1.127-1.127H63.482a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H33.045a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-9.252 -126.881)" fill="#f4ad5e"/>
          <path id="Path_94" data-name="Path 94" d="M31.918,314.678a1.127,1.127,0,0,1,1.127-1.127H53.337a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H33.045a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-9.252 -136.794)" fill="#f4ad5e"/>
          <path id="Path_95" data-name="Path 95" d="M363.756,97.943a1.127,1.127,0,0,1,1.127-1.127h44.528a1.127,1.127,0,0,1,1.127,1.127v21.419a1.128,1.128,0,0,1-1.127,1.127H364.883a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-188.34 -19.825)" fill="#fff"/>
          <path id="Path_96" data-name="Path 96" d="M362.531,97.283a1.691,1.691,0,0,1,1.691-1.691H408.75a1.691,1.691,0,0,1,1.691,1.691V118.7a1.691,1.691,0,0,1-1.691,1.691H364.222a1.691,1.691,0,0,1-1.691-1.691Zm1.691-.564a.564.564,0,0,0-.564.564V118.7a.564.564,0,0,0,.564.563H408.75a.564.564,0,0,0,.564-.563V97.283a.564.564,0,0,0-.564-.564Z" transform="translate(-187.679 -19.164)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_97" data-name="Path 97" d="M379.674,110.188a1.127,1.127,0,0,1,1.127-1.127h30.437a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H380.8a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-196.931 -26.434)" fill="#032068"/>
          <path id="Path_98" data-name="Path 98" d="M379.674,128.555a1.127,1.127,0,0,1,1.127-1.127h20.291a1.127,1.127,0,0,1,1.127,1.127v1.127a1.127,1.127,0,0,1-1.127,1.127H380.8a1.127,1.127,0,0,1-1.127-1.127Z" transform="translate(-196.931 -36.346)" fill="#032068"/>
          <path id="Path_99" data-name="Path 99" d="M30.693,142.589a1.691,1.691,0,0,1,1.691-1.691H54.427a1.691,1.691,0,0,1,1.691,1.691v30.677a1.691,1.691,0,0,1-1.691,1.691H32.384a1.691,1.691,0,0,1-1.691-1.691Z" transform="translate(-8.591 -43.615)" fill="#f4ad5e"/>
          <path id="Path_100" data-name="Path 100" d="M57.027,153.306H42.156v-1.127H57.027Z" transform="translate(-14.777 -49.704)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_101" data-name="Path 101" d="M57.027,165.811H42.156v-1.127H57.027Z" transform="translate(-14.777 -56.452)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_102" data-name="Path 102" d="M57.027,177.275H42.156v-1.127H57.027Z" transform="translate(-14.777 -62.639)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_103" data-name="Path 103" d="M57.027,188.738H42.156v-1.127H57.027Z" transform="translate(-14.777 -68.826)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_104" data-name="Path 104" d="M51.271,201.243H42.156v-1.127h9.114Z" transform="translate(-14.777 -75.575)" fill="#fff" fill-rule="evenodd"/>
          <path id="Path_105" data-name="Path 105" d="M58.828,166.558a1.691,1.691,0,0,1,1.691-1.691H82.561a1.69,1.69,0,0,1,1.691,1.691v30.677a1.691,1.691,0,0,1-1.691,1.691H60.519a1.691,1.691,0,0,1-1.691-1.691Z" transform="translate(-23.775 -56.551)" fill="#fff"/>
          <path id="Path_106" data-name="Path 106" d="M57.6,165.9a2.255,2.255,0,0,1,2.255-2.255H81.9a2.255,2.255,0,0,1,2.255,2.255v30.677a2.255,2.255,0,0,1-2.255,2.255H59.858a2.255,2.255,0,0,1-2.255-2.255Zm2.255-1.127a1.127,1.127,0,0,0-1.127,1.127v30.677a1.127,1.127,0,0,0,1.127,1.127H81.9a1.128,1.128,0,0,0,1.127-1.127V165.9a1.128,1.128,0,0,0-1.127-1.127Z" transform="translate(-23.114 -55.89)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_107" data-name="Path 107" d="M85.162,177.275H70.291v-1.127H85.162Z" transform="translate(-29.961 -62.639)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_108" data-name="Path 108" d="M85.162,189.78H70.291v-1.127H85.162Z" transform="translate(-29.961 -69.388)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_109" data-name="Path 109" d="M85.162,201.243H70.291v-1.127H85.162Z" transform="translate(-29.961 -75.575)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_110" data-name="Path 110" d="M85.162,212.707H70.291V211.58H85.162Z" transform="translate(-29.961 -81.762)" fill="#032068" fill-rule="evenodd"/>
          <path id="Path_111" data-name="Path 111" d="M79.405,225.212H70.291v-1.127h9.114Z" transform="translate(-29.961 -88.51)" fill="#032068" fill-rule="evenodd"/>
          <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="1.409" cy="1.409" rx="1.409" ry="1.409" transform="translate(178.234 107.992)" fill="#032068"/>
          <path id="Path_112" data-name="Path 112" d="M293.667,207.808v-5.073l-3.382,2.536Z" transform="translate(-148.689 -76.988)" fill="#032068"/>
          <path id="Path_113" data-name="Path 113" d="M330.315,166.612v8.117a11.048,11.048,0,0,1-11.048,11.048H293.959" transform="translate(-150.671 -57.493)" fill="none" stroke="#032068" stroke-width="2"/>
          <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="1.409" cy="1.409" rx="1.409" ry="1.409" transform="translate(53.103 197.612) rotate(180)" fill="#032068"/>
          <path id="Path_114" data-name="Path 114" d="M160.489,396.379v-5.073l3.382,2.536Z" transform="translate(-78.64 -178.757)" fill="#032068"/>
          <path id="Path_115" data-name="Path 115" d="M94.978,355.184V363.3a11.048,11.048,0,0,0,11.047,11.048h20.8" transform="translate(-43.284 -159.263)" fill="none" stroke="#032068" stroke-width="2"/>
        </g>
      </svg>
      </div>
      <h1>Welcome!</h1>
      <p>Log in to access your account</p>
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <input type="text" v-model="username" placeholder="Username, email, or phone number" required>
        </div>
        <div class="input-group">
          <input type="password" v-model="password" placeholder="Password" required>
        </div>
        <div class="remember-me">
          <input type="checkbox" id="rememberMe" v-model="rememberMe">
          <label for="rememberMe">Remember me</label>
        </div>
        <button type="submit">Sign in</button>
      </form>
      <div class="footer-links">
        <p><router-link to="/forgotpass">Forgot your password!</router-link></p>
        <p>Don’t have an account? <router-link to="/register">Register</router-link></p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      rememberMe: false,
      notificationMessage: '',
      progressWidth: 0,
      progressInterval: null,
      notificationClass: '',
      notificationIcon: '',
    };
  },
  methods: {
    handleSubmit() {
      // Simulate different login scenarios
      if (this.username === 'user@example.com' && this.password === 'password123') {
        this.notificationMessage = 'Login successfully';
        this.notificationClass = 'success';
        this.notificationIcon = '✔';
      } else if (this.username === 'banned@example.com') {
        this.notificationMessage = 'Your account is disabled';
        this.notificationClass = 'error';
        this.notificationIcon = '✖';
      } else {
        this.notificationMessage = 'Invalid email or password';
        this.notificationClass = 'error';
        this.notificationIcon = '✖';
      }
      this.startProgressBar();
      setTimeout(() => {
        this.notificationMessage = '';
      }, 3500);
    },
    startProgressBar() {
      this.progressWidth = 100;
      if (this.progressInterval) clearInterval(this.progressInterval);
      this.progressInterval = setInterval(() => {
        if (this.progressWidth > 0) {
          this.progressWidth -= 0.5;
        } else {
          clearInterval(this.progressInterval);
        }
      }, 25);
    },
    closeNotification() {
      this.notificationMessage = '';
    },
    resetNotification() {
      this.progressWidth = 0;
      clearInterval(this.progressInterval);
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

body {
  font-family: 'Nunito', sans-serif;
}

.svg-container {
  margin-top: 10px;
}

.login-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h1 {
  margin-top: 5px;
  margin-bottom: -15px;
  font-weight: 700;
  font-size: 1.5rem;
  font-family: 'Nunito', sans-serif;
}

p {
  margin-bottom: 10px;
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Nunito', sans-serif;
  height: 2.7rem;
  padding: 0.6rem 0.8rem;
  margin-bottom: -8px;
}

.remember-me {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me input {
  margin-right: 0.5rem;
}

.remember-me label {
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #6c63ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
}

button:hover {
  background-color: #5753d6;
}

.footer-links {
  margin-top: 1.5rem;
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
}

.footer-links a {
  color: #6c63ff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.notification-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  transition: opacity 1s ease;
  cursor: pointer;
}

.notification-popup.error {
  background-color: #f44336;
  color: #fff;
}

.notification-popup.success {
  background-color: #4caf50;
  color: #fff;
}

.notification-icon {
  font-size: 1rem;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.5s linear;
}

/* Animation for fading */
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
