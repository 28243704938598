<template>
  <nav class="bottom-nav">
    <router-link to="/home" :class="{ active: $route.name === 'Home' }">
      <div class="nav-item">
        <i v-if="$route.name !== 'Home'">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.379" viewBox="0 0 22 19.379">
            <g id="Group_28" data-name="Group 28" transform="translate(-1052 -745.282)">
              <path id="Path_316" data-name="Path 316" d="M237.451,90.577a1.307,1.307,0,0,1,1.6,0L248,97.546a.782.782,0,0,0,1.079-.117.734.734,0,0,0-.12-1.049l-8.955-6.969a2.876,2.876,0,0,0-3.516,0l-8.954,6.969a.733.733,0,0,0-.121,1.049.783.783,0,0,0,1.08.117Z" transform="translate(824.75 656.47)" fill="#127569"/>
              <path id="Path_317" data-name="Path 317" d="M240.034,92.911a2.918,2.918,0,0,0-3.545,0l-6.448,4.978a.737.737,0,0,0-.291.583v6.471a2.788,2.788,0,0,0,2.837,2.738h2.837v-5.226a2.839,2.839,0,0,1,5.674,0v5.226h2.837a2.788,2.788,0,0,0,2.837-2.738V98.472a.739.739,0,0,0-.29-.583Z" transform="translate(824.739 656.981)" fill="#127569"/>
            </g>
          </svg>
        </i>
        <span v-if="$route.name === 'Home'">Home</span>
      </div>
    </router-link>
    <router-link to="/props" :class="{ active: $route.name === 'Props' }">
      <div class="nav-item">
        <i v-if="$route.name !== 'Props'" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g id="Group_29" data-name="Group 29" transform="translate(-683 -750.25)">
              <path id="Path_224" data-name="Path 224" d="M10.75,89.865a8.874,8.874,0,1,0,8.664,6.91.808.808,0,0,1,1.575-.358,10.489,10.489,0,1,1-7.907-7.907.808.808,0,0,1-.358,1.575A8.915,8.915,0,0,0,10.75,89.865Z" transform="translate(682.75 662)" fill="#127569"/>
              <path id="Path_225" data-name="Path 225" d="M10.522,92.866a5.649,5.649,0,1,0,5.465,4.193.808.808,0,0,1,1.561-.417A7.265,7.265,0,1,1,12.013,91.4a.808.808,0,0,1-.33,1.582A5.684,5.684,0,0,0,10.522,92.866Z" transform="translate(682.979 662.229)" fill="#127569"/>
              <path id="Path_226" data-name="Path 226" d="M7.864,98.286a2.421,2.421,0,0,1,2.421-2.421.807.807,0,1,0,0-1.614,4.036,4.036,0,1,0,4.036,4.036.807.807,0,1,0-1.614,0,2.421,2.421,0,0,1-4.843,0Z" transform="translate(683.214 662.464)" fill="#127569"/>
              <path id="Path_227" data-name="Path 227" d="M14.7,95.213H17.59a.807.807,0,0,0,.571-.236l1.391-1.391a1.883,1.883,0,0,0,0-2.663l-1.707-1.707a1.883,1.883,0,0,0-2.663,0l-1.391,1.391a.807.807,0,0,0-.236.571v2.894L9.486,98.14a.807.807,0,0,0,1.141,1.141Z" transform="translate(683.45 662.031)" fill="#127569"/>
            </g>
          </svg>
        </i>
        <span v-if="$route.name === 'Props'">Props</span>
      </div>
    </router-link>
    <router-link to="/funds" :class="{ active: $route.name === 'Funds' }">
      <div class="nav-item">
        <i v-if="$route.name !== 'Funds'" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.905" height="17.934" viewBox="0 0 25.905 17.934">
            <g id="Group_30" data-name="Group 30" transform="translate(-547 -746.25)">
              <path id="Path_216" data-name="Path 216" d="M15.217,88.25a8.967,8.967,0,1,0,8.967,8.967A8.967,8.967,0,0,0,15.217,88.25Zm0,3.786a1,1,0,0,1,1,1v.127A2.425,2.425,0,0,1,17.881,94.3a1,1,0,0,1-1.7,1.042.414.414,0,0,0-.253-.2,2.3,2.3,0,0,0-.665-.067h-.092a2.112,2.112,0,0,0-.9.154.255.255,0,0,0-.107.087.439.439,0,0,0-.043.231.476.476,0,0,0,.358.46l1.962.493a2.469,2.469,0,0,1,1.864,2.392,2.4,2.4,0,0,1-.336,1.272,2.237,2.237,0,0,1-.874.807,3.17,3.17,0,0,1-.882.3v.132a1,1,0,0,1-1.993,0v-.125a2.428,2.428,0,0,1-1.669-1.148,1,1,0,0,1,1.7-1.04.418.418,0,0,0,.254.207,2.18,2.18,0,0,0,.63.067q.04,0,.081,0c.026,0,.052,0,.078,0a2.062,2.062,0,0,0,.868-.153.256.256,0,0,0,.107-.087.438.438,0,0,0,.043-.231.476.476,0,0,0-.359-.46l-1.96-.492a2.469,2.469,0,0,1-1.865-2.393,2.4,2.4,0,0,1,.336-1.272,2.234,2.234,0,0,1,.873-.807,3.162,3.162,0,0,1,.882-.3v-.134A1,1,0,0,1,15.218,92.036Z" transform="translate(548.721 658)" fill="#127569" fill-rule="evenodd"/>
              <path id="Path_217" data-name="Path 217" d="M1.246,94.25a1,1,0,1,0,0,1.993H5.9a1,1,0,1,0,0-1.993Z" transform="translate(546.75 659.971)" fill="#127569"/>
              <path id="Path_218" data-name="Path 218" d="M3.246,98.25a1,1,0,0,0,0,1.993H5.9a1,1,0,0,0,0-1.993Z" transform="translate(547.407 661.284)" fill="#127569"/>
              <path id="Path_219" data-name="Path 219" d="M3.246,90.25a1,1,0,1,0,0,1.993H5.9a1,1,0,1,0,0-1.993Z" transform="translate(547.407 658.657)" fill="#127569"/>
            </g>
          </svg>

        </i>
        <span v-if="$route.name === 'Funds'">Funds</span>
      </div>
    </router-link>
    <router-link to="/wallet" :class="{ active: $route.name === 'Wallet' }">
      <div class="nav-item">
        <i v-if="$route.name !== 'Wallet'" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18.86" viewBox="0 0 21 18.86">
            <g id="Group_31" data-name="Group 31" transform="translate(-614 -744.25)">
              <path id="Path_221" data-name="Path 221" d="M.25,90.675v12.394a4.04,4.04,0,0,0,4.038,4.041H17.212a4.04,4.04,0,0,0,4.038-4.041V102.8H15.6a3.5,3.5,0,0,1,0-7.005H21.25v-.269a4.04,4.04,0,0,0-4.038-4.041h-2.5A4.041,4.041,0,0,0,10.75,88.25H2.673A2.424,2.424,0,0,0,.25,90.675Zm12.785.808H2.673a.808.808,0,0,1,0-1.617H10.75A2.424,2.424,0,0,1,13.035,91.483ZM3.481,103.069a.808.808,0,0,1,.808-.808H8.6a.808.808,0,0,1,0,1.617H4.288A.808.808,0,0,1,3.481,103.069Z" transform="translate(613.75 656)" fill="#127569" fill-rule="evenodd"/>
              <path id="Path_222" data-name="Path 222" d="M20.294,96.75v3.772H14.636a1.886,1.886,0,0,1,0-3.772Zm-5.658.808a1.078,1.078,0,1,0,1.078,1.078A1.078,1.078,0,0,0,14.636,97.558Z" transform="translate(614.706 656.661)" fill="#127569" fill-rule="evenodd"/>
            </g>
          </svg>
        </i>
        <span v-if="$route.name === 'Wallet'">Wallet</span>
      </div>
    </router-link>

    <router-link to="/referrals" :class="{ active: $route.name === 'Referrals' }">
      <div class="nav-item">
        <i v-if="$route.name !== 'Referrals'" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="17.979" viewBox="0 0 22.001 17.979">
            <g id="Group_32" data-name="Group 32" transform="translate(-134 -711.25)">
              <path id="Path_202" data-name="Path 202" d="M130.089,91.25a3.339,3.339,0,1,0,3.339,3.339A3.339,3.339,0,0,0,130.089,91.25Z" transform="translate(20.005 620)" fill="#127569"/>
              <path id="Path_203" data-name="Path 203" d="M121.1,92.25a3.853,3.853,0,1,0,3.853,3.853A3.853,3.853,0,0,0,121.1,92.25Z" transform="translate(19.832 620.027)" fill="#127569"/>
              <path id="Path_204" data-name="Path 204" d="M130.118,99.25a5.911,5.911,0,0,0-4.177,1.73c-.09.09-.178.184-.261.279A8.459,8.459,0,0,1,128.7,105a8.32,8.32,0,0,1,.324.932h6.229a.771.771,0,0,0,.77-.771,5.908,5.908,0,0,0-5.907-5.907Z" transform="translate(19.975 620.219)" fill="#127569"/>
              <path id="Path_205" data-name="Path 205" d="M118.531,101.778a6.935,6.935,0,0,1,9.588,6.406.771.771,0,0,1-.771.771H115.021a.771.771,0,0,1-.771-.771,6.933,6.933,0,0,1,4.281-6.406Z" transform="translate(19.75 620.273)" fill="#127569"/>
            </g>
          </svg>
        </i>
        <span v-if="$route.name === 'Referrals'">Refers</span>
      </div>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'BottomNav'
}
</script>

<style scoped>
.bottom-nav {
  display: flex;
  justify-content: space-between; /* توزیع یکنواخت آیتم‌ها */
  align-items: center;
  position: fixed;
  height: 60px;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 15px 15px; /* کاهش پدینگ برای جلوگیری از خروج از صفحه */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}


.bottom-nav a {
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* اضافه شده برای قرار دادن متن زیر آیکون */
  justify-content: center;
  align-items: center;
  height: 42px;
  color: #127569;
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-size: 19px;
  font-weight: bold;
  padding: 0 7px;
  width: 100px;
}

.bottom-nav a .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* عرض 100 درصد برای توزیع یکنواخت */
}

.bottom-nav a svg {
  margin-top: 8px;
  margin-right: 0px;
  margin-left: 0px;
}

.bottom-nav a.active {
  background-color: #C7DBD8; /* رنگ پس‌زمینه برای آیتم فعال */
  border-radius: 15px;
  color: #127569; /* رنگ متن برای آیتم فعال */
}

.bottom-nav a .nav-item {
  display: flex;
  align-items: center;
  padding: 10px 0px; /* فضای داخلی بیشتر برای آیتم‌ها */
  justify-content: center; /* آیتم‌ها را در مرکز قرار می‌دهد */
}

</style>
