<template>
  <div class="cards-container">
    <div class="card" :style="{ borderColor: '#FDE8E2', backgroundColor: '#FDE8E2' }">
      <div class="header">
        <div class="icon-wrapper" :style="{ backgroundColor: '#F87462' }">
          <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2a10 10 0 1 1-10 10 10.011 10.011 0 0 1 10-10m0-2a12 12 0 1 0 12 12A12 12 0 0 0 12 0z M12 7a5 5 0 0 0-5 5h10a5 5 0 0 0-5-5z" fill="white"></path>
          </svg>
        </div>
        <div class="title">Props</div>
      </div>
      <div class="content">
        <div class="info-item">
          <span>Total:</span>
          <span>296</span>
        </div>
        <div class="info-item">
          <span>Active:</span>
          <span>8</span>
        </div>
        <div class="info-item">
          <span>Profit:</span>
          <span>$1,124</span>
        </div>
      </div>
    </div>

    <div class="card" :style="{ borderColor: '#E5E5FF', backgroundColor: '#E5E5FF' }">
      <div class="header">
        <div class="icon-wrapper" :style="{ backgroundColor: '#6B5FEE' }">
          <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1a11 11 0 0 0-11 11v4.53l-1 1V23h6v-2H2v-5.47l.97-.97H2v-3.56a9.04 9.04 0 0 1 9-9V4a8 8 0 0 1 8 8v3h-3v2h3a1 1 0 0 0 1-1v-4.53A11 11 0 0 0 12 1zM12 6a5 5 0 0 0-5 5h10a5 5 0 0 0-5-5z" fill="white"></path>
          </svg>
        </div>
        <div class="title">Funds</div>
      </div>
      <div class="content">
        <div class="info-item">
          <span>Total:</span>
          <span>296</span>
        </div>
        <div class="info-item">
          <span>Active:</span>
          <span>8</span>
        </div>
        <div class="info-item">
          <span>Profit:</span>
          <span>$1,124</span>
        </div>
      </div>
    </div>

    <div class="card" :style="{ borderColor: '#E0F2F1', backgroundColor: '#E0F2F1' }">
      <div class="header">
        <div class="icon-wrapper" :style="{ backgroundColor: '#4CAF50' }">
          <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2A10 10 0 0 1 22 12a9.93 9.93 0 0 1-.15 1.74L15 11V8.27A7.79 7.79 0 0 0 12 8a8 8 0 1 0 8 8 7.79 7.79 0 0 0-.27-2.73L20 15v3h3a10 10 0 1 1-10-10z" fill="white"></path>
          </svg>
        </div>
        <div class="title">Invests</div>
      </div>
      <div class="content">
        <div class="info-item">
          <span>Total:</span>
          <span>296</span>
        </div>
        <div class="info-item">
          <span>Active:</span>
          <span>8</span>
        </div>
        <div class="info-item">
          <span>Profit:</span>
          <span>$1,124</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

* {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.cards-container {
  margin-top: 150px;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f4f4f4;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: 150px;
  height: 133px; /* تنظیم ارتفاع کارت */
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
border-radius: 30%;
  width: 40px;
  height: 40px;
}

.icon {
  width: 24px;
  height: 24px;
}

.title {
  font-weight: bold;

  margin-right: 18px;
}

.content {
  text-align: left;
  width: 100%;
}

.info-item {
  display: flex;
  justify-content: space-between;
}
</style>
