<template>
  <div class="home-nav">
    <div class="row profile-row">
      <svg class="profile-image" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120">
        <g id="Group_60" data-name="Group 60" transform="translate(-6637 -674)">
          <circle id="Ellipse_17" data-name="Ellipse 17" cx="60" cy="60" r="60" transform="translate(6637 674)"/>
        </g>
      </svg>

    </div>
    <div class="row name-row">
      <div class="profile-details">
        <div class="profile-name">
          <svg class="profile-name-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.642" height="19.642" viewBox="0 0 19.642 19.642">
              <path id="Path_386" data-name="Path 386" d="M13.945,90.984a2.75,2.75,0,0,0-3.889,0l-.9.9a1.25,1.25,0,0,1-.884.366H7A2.75,2.75,0,0,0,4.25,95v1.272a1.25,1.25,0,0,1-.366.884l-.9.9a2.749,2.749,0,0,0,0,3.889l.9.9a1.249,1.249,0,0,1,.366.884V105A2.75,2.75,0,0,0,7,107.75H8.272a1.252,1.252,0,0,1,.884.366l.9.9a2.752,2.752,0,0,0,3.889,0l.9-.9a1.252,1.252,0,0,1,.884-.366H17A2.75,2.75,0,0,0,19.75,105v-1.272a1.249,1.249,0,0,1,.366-.884l.9-.9a2.749,2.749,0,0,0,0-3.889l-.9-.9a1.25,1.25,0,0,1-.366-.884V95A2.75,2.75,0,0,0,17,92.25H15.728a1.25,1.25,0,0,1-.884-.366Zm1.578,7.554a.75.75,0,1,0-1.044-1.077l-3.6,3.493L9.522,99.643a.75.75,0,1,0-1.044,1.077l1.875,1.818a.748.748,0,0,0,1.044,0Z" transform="translate(-2.179 -90.179)" fill-rule="evenodd"/>
            </svg>
          </svg>
          Farzin Afrasiabi
          <div class="profile-status">
            <span class="status-text">Beginner</span>
            <svg class="status-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="19.5" viewBox="0 0 14.5 19.5">
                <g id="Group_58" data-name="Group 58" transform="translate(-364.25 -180.25)">
                  <g id="Group_57" data-name="Group 57" transform="translate(359.5 178)">
                    <path id="Path_387" data-name="Path 387" d="M11.41,8.026Q11.7,8,12,8a6.473,6.473,0,0,1,2.931.7l2.94-4.115A1,1,0,0,0,17.057,3H15.515a1,1,0,0,0-.814.419Zm0,0a6.459,6.459,0,0,0-2.341.67L6.129,4.581A1,1,0,0,1,6.943,3H8.485a1,1,0,0,1,.814.419L12,7.2Z" fill="none" stroke="#000" stroke-width="1.5"/>
                    <path id="Path_388" data-name="Path 388" d="M12.5,17V12L11,13.5m7.5,1A6.5,6.5,0,1,1,12,8,6.5,6.5,0,0,1,18.5,14.5Z" fill="none" stroke="#ff6b00" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </g>
              </svg>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="row balance-row">
      <div class="balance-section">
        <div class="balance-header">
          <div class="balance-info">
            <svg class="balance-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="23.558" height="21.157" viewBox="0 0 23.558 21.157">
                <g id="Group_59" data-name="Group 59" transform="translate(0 0)">
                  <path id="Path_221" data-name="Path 221" d="M.25,90.97v13.9a4.532,4.532,0,0,0,4.53,4.534h14.5a4.532,4.532,0,0,0,4.53-4.534v-.3H17.465a3.929,3.929,0,0,1,0-7.858h6.342v-.3a4.532,4.532,0,0,0-4.53-4.534H16.469a4.533,4.533,0,0,0-4.44-3.627H2.968A2.719,2.719,0,0,0,.25,90.97Zm14.342.907H2.968a.907.907,0,0,1,0-1.813h9.061A2.719,2.719,0,0,1,14.592,91.877Zm-10.718,13a.907.907,0,0,1,.906-.907H9.613a.907.907,0,0,1,0,1.813H4.78A.907.907,0,0,1,3.874,104.874Z" transform="translate(-0.25 -88.25)" fill="#fff" fill-rule="evenodd"/>
                  <path id="Path_222" data-name="Path 222" d="M21.213,96.75v4.231H14.866a2.116,2.116,0,1,1,0-4.231Zm-6.347.907a1.209,1.209,0,1,0,1.209,1.209A1.209,1.209,0,0,0,14.866,97.657Z" transform="translate(2.345 -86.474)" fill="#fff" fill-rule="evenodd"/>
                </g>
              </svg>
            </svg>
            <span class="balance-text">Balance:</span>
          </div>
          <div class="balance-amount">$ 12,222.70</div>
        </div>
      </div>
    </div>
    <div class="row actions-row">
      <button class="action-button" @click="goToDeposit">
        <svg class="action-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.987" height="21.987" viewBox="0 0 21.987 21.987">
            <path id="Path_392" data-name="Path 392" d="M129.99,10.993a5,5,0,0,0-4.712,3.331h-8.057A2.22,2.22,0,0,1,115,12.1V3.221A2.22,2.22,0,0,1,117.221,1h12.214a2.22,2.22,0,0,1,2.221,2.221v8.057A4.986,4.986,0,0,0,129.99,10.993Zm0,0a5,5,0,1,0,5,5A5,5,0,0,0,129.99,10.993ZM115,5.442h16.656m-13.325,5.552h3.331m8.328,3.331V15.99m0,0v1.666m0-1.666h1.666m-1.666,0h-1.666" transform="translate(-114)" fill="none" stroke="#127569" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>

        </svg>
        <span class="action-text">Deposit</span>
      </button>
      <button class="action-button" @click="goToWithdraw">
        <svg class="action-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.987" height="21.987" viewBox="0 0 21.987 21.987">
            <path id="Path_393" data-name="Path 393" d="M243.99,10.993a5,5,0,0,0-4.712,3.331h-8.057A2.22,2.22,0,0,1,229,12.1V3.221A2.22,2.22,0,0,1,231.221,1h12.214a2.22,2.22,0,0,1,2.221,2.221v8.057A4.986,4.986,0,0,0,243.99,10.993Zm0,0a5,5,0,1,0,5,5A5,5,0,0,0,243.99,10.993ZM229,5.442h16.656m-13.325,5.552h3.331m9.993,5h-3.331" transform="translate(-228)" fill="none" stroke="#127569" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>


        </svg>
        <span class="action-text">Withdraw</span>
      </button>
      <button class="transactions-button" @click="goToTransactions">
        <svg class="action-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.987" height="21.987" viewBox="0 0 21.987 21.987">
            <path id="Path_394" data-name="Path 394" d="M15.99,10.993a5,5,0,0,0-4.712,3.331H3.221A2.221,2.221,0,0,1,1,12.1V3.221A2.221,2.221,0,0,1,3.221,1H15.435a2.221,2.221,0,0,1,2.221,2.221v8.057A4.987,4.987,0,0,0,15.99,10.993Zm0,0a5,5,0,1,0,5,5A5,5,0,0,0,15.99,10.993Zm1.666,3.886L15.574,17.1l-1.249-1.332M1,5.442H17.656M4.331,10.993H7.662" fill="none" stroke="#127569" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>


        </svg>
        <span class="action-text">Transactions</span>
      </button>
    </div>
  </div>
  <div class="home">
    <div class="chart-container">
      <div class="header">
        <h2>Earnings</h2>
        <div class="timeframe-buttons">
          <button :class="{'active': timeframe === 'D'}" @click="setTimeframe('D')">D</button>
          <button :class="{'active': timeframe === 'W'}" @click="setTimeframe('W')">W</button>
          <button :class="{'active': timeframe === 'M'}" @click="setTimeframe('M')">M</button>
          <div class="active-indicator" :style="activeIndicatorStyle"></div>
        </div>
      </div>
      <div class="canvas-wrapper">
        <canvas id="earningsChart"></canvas>
      </div>
      <div class="labels">
        <span class="label first"><span class="color-box"></span>Invest</span>
        <span class="label second"><span class="color-box"></span>Prop</span>
        <span class="label third"><span class="color-box"></span>Fund</span>
        <span class="label fourth"><span class="color-box"></span>Referral</span>
      </div>
    </div>
  </div>
</template>


<script>
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import Referrals from "@/views/Referrals.vue";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export default {
  components: {Referrals},
  data() {
    return {
      profileImageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9x_UNMEEPTPvj2E_2G23vhebSnkTB5newlA&s',
      timeframe: 'D',
      chart: null,
      data: this.getDailyData(),
      yAxisColor: '#e0e0e0',
      yAxisWidth: 1,
      dailyDates: [
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28'
      ],
      weeklyDates: [
        'W 1',
        'W 2',
        'W 3',
        'W 4',
        'W 5',
        'W 6',
        'W 7',
        'W 8'
      ],
      monthlyDates: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    };
  },
  computed: {
    activeIndicatorStyle() {
      const positions = {
        D: '0%',
        W: '33.33%',
        M: '66.66%'
      };
      return {
        left: positions[this.timeframe],
        transition: 'left 0.3s ease'
      };
    }
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    getDailyData() {
      return {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
          {
            label: 'Invest',
            data: [1000, 1500, 2000, 2500, 3000, 2000, 1000],
            backgroundColor: '#4285F4',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Prop',
            data: [200, 300, 400, 500, 600, 400, 200],
            backgroundColor: '#FBBC05',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Fund',
            data: [100, 200, 300, 400, 500, 300, 100],
            backgroundColor: '#34A853',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Referral',
            data: [50, 100, 150, 200, 250, 150, 50],
            backgroundColor: '#EA4335',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
        ],
      };
    },
    getWeeklyData() {
      return {
        labels: this.weeklyDates,
        datasets: [
          {
            label: 'Invest',
            data: [7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000],
            backgroundColor: '#4285F4',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Prop',
            data: [1400, 1600, 1800, 2000, 2200, 2400, 2600, 2800],
            backgroundColor: '#FBBC05',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Fund',
            data: [700, 900, 1100, 1300, 1500, 1700, 1900, 2100],
            backgroundColor: '#34A853',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Referral',
            data: [350, 450, 550, 650, 750, 850, 950, 1050],
            backgroundColor: '#EA4335',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
        ],
      };
    },
    getMonthlyData() {
      return {
        labels: this.monthlyDates,
        datasets: [
          {
            label: 'Invest',
            data: [3000, 3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000, 5200],
            backgroundColor: '#4285F4',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Prop',
            data: [600, 640, 680, 720, 760, 800, 840, 880, 920, 960, 1000, 1040],
            backgroundColor: '#FBBC05',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Fund',
            data: [300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960],
            backgroundColor: '#34A853',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          {
            label: 'Referral',
            data: [150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480],
            backgroundColor: '#EA4335',
            borderRadius: {
              topLeft: 4,
              topRight: 4,
              bottomLeft: 4,
              bottomRight: 4,
            },
            borderSkipped: false,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
        ],
      };
    },
    renderChart() {
      const ctx = document.getElementById('earningsChart').getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                title: (tooltipItems) => {
                  const index = tooltipItems[0].dataIndex;
                  const date = this.timeframe === 'D' ? this.dailyDates[index] : this.timeframe === 'W' ? this.weeklyDates[index] : this.monthlyDates[index];
                  return tooltipItems[0].label + '\n' + date;
                },
                label: (tooltipItem) => {
                  return `${tooltipItem.dataset.label}: $${tooltipItem.raw.toLocaleString()}`;
                },
                footer: (tooltipItems) => {
                  const totals = tooltipItems.reduce((acc, item) => acc + item.raw, 0);
                  return `Total: $${totals.toLocaleString()}`;
                }
              }
            }
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            y: {
              stacked: true,
              display: false,
              grid: {
                display: true,
                drawBorder: false,
                color: '#e0e0e0',
                lineWidth: 1,
              },
              position: 'right',
              ticks: {
                stepSize: 1000,
                callback: function(value) {
                  return value === 0 ? null : value.toLocaleString();
                },
              },
              afterBuildTicks: function(scale) {
                scale.ticks.shift();
              },
            },
          },
        },
      });
    },
    setTimeframe(timeframe) {
      this.timeframe = timeframe;
      if (timeframe === 'D') {
        this.data = this.getDailyData();
      } else if (timeframe === 'W') {
        this.data = this.getWeeklyData();
      } else if (timeframe === 'M') {
        this.data = this.getMonthlyData();
      }
      this.renderChart();
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

* {
  -ms-overflow-style: none;  /* IE و Edge */
  scrollbar-width: none;  /* فایرفاکس */
  font-family: 'Nunito', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  display: none;  /* کروم، سافاری و جدیدترین نسخه‌های اج */
}

.home-nav {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  height: 215px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-row {
  justify-content: center;
}

.profile-image {
  margin-top: -10px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.name-row {
  margin-top: -10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-name {
  font-size: 18px;
  color: #000000;
  display: flex;
  align-items: center;
}

.profile-name-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.profile-status {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.status-icon {
  width: 20px;
  height: 20px;
  margin-left: -23px;
}

.status-text {
  font-size: 14px;
  background-color: #e8f5f4;
  color: #127569;
  padding: 5px 10px;
  width: 100px;
  height: 30px;
  border-radius: 10px;
}

.balance-row {
  margin-top: -10px;
  justify-content: center;
  width: 100%;
}

.balance-section {
  background-color: #127569;
  padding: 20px;
  border-radius: 10px;
  height: 80px;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.balance-header {
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balance-info {
  display: flex;
  align-items: center;
}

.balance-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.balance-text {
  font-size: 18px;
}

.balance-amount {
  font-size: 24px;
}

.actions-row {
  margin-top: -45px;
  height: 37px;
  justify-content: space-between;
  width: 95%;
}

.action-button {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 15px;
  padding: 10px 7px;
  border-width: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(34.33% - 13px);
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}

.transactions-button {
  background-color: #ffffff;
  border-color: #ffffff;
  border-width: 0;
  border-radius: 15px;
  padding: 10px 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(38.33% - 13px);
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}
.action-button:hover {
  background-color: #e8f5f4;
}

.action-icon {

  width: 22px;
  height: 22px;
  margin-right: 4px;
  margin-top: 2px;
}

.action-text {
  font-size: 13px;
  font-weight: bold;
  color: #127569;
}

.home {
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
}

.chart-container {
  margin-top: 45px;
  flex-grow: 1;
  max-width: 600px; /* حداکثر عرض را تنظیم کنید */
  width: 100%; /* عرض کامل */
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

h2 {
  font-size: 1.5rem;
  margin-top: -20px;
}

.timeframe-buttons {
  position: relative;
  display: flex;
  background: #f7f7f7;
  border-radius: 10px;
  padding: 5px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeframe-buttons button {
  margin-left: -1px;
  margin: 2px 7px;
  background: none;
  border: none;
  padding: 5px 7px;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  position: relative;
  z-index: 1;
}

.timeframe-buttons button.active {
  font-weight: bold;
}

.active-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: 5px;
  margin-top: 5px;
  width: 25.33%;
  height: 36px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease;
}
.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 300px;
}

.labels {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.label {
  font-size: 1.1rem;
  display: flex;
  align-items: center; /* اضافه کردن این خط */
}

.label .color-box {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 5px; /* مارجین به مربع اضافه شده */
  margin-top: 0px;
}

.label.first .color-box {
  background-color: #4285F4;
}

.label.second .color-box {
  background-color: #FBBC05;
}

.label.third .color-box {
  background-color: #34A853;
}

.label.fourth .color-box {
  background-color: #EA4335;
}



</style>
