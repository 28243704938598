<template>
  <div class="home-nav">
    <div class="row top-row">
      <div class="logo-wrapper">
        <div class="logo">
          <span class="logo-text">Prop</span><span class="logo-text2">yio</span><span class="logo-dot">.</span>
        </div>
      </div>
      <div class="icon-wrapper">
        <div class="notification-wrapper" @click="goToNotifications">
          <svg class="notification-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="23.394" height="26.068" viewBox="0 0 23.394 26.068">
              <g id="Group_46" data-name="Group 46" transform="translate(1 1)">
                <path id="Path_247" data-name="Path 247" d="M13.794,15a4.011,4.011,0,1,1-7.566,0Z" transform="translate(0.686 3.719)" fill="none" stroke="#127569" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_248" data-name="Path 248" d="M19.337,11V8.641a7.641,7.641,0,0,0-15.281,0V11a4.475,4.475,0,0,1-1.528,3.368l-.586.513a2.759,2.759,0,0,0,1.817,4.836H19.634a2.759,2.759,0,0,0,1.817-4.836l-.586-.513A4.475,4.475,0,0,1,19.337,11Z" transform="translate(-1 -1)" fill="none" stroke="#127569" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>
          </svg>
        </div>
        <div class="menu-wrapper" @click="toggleMenu">
          <div class="menu-icon">⋮</div>
          <div v-if="isMenuOpen" class="dropdown-menu">
            <ul>
              <li @click="goToProfile">
                <svg class="dropdown-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <span class="dropdown-list">Profile</span>
              </li>
              <li @click="goToSupport">
                <svg class="dropdown-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M12 16v-4"></path><path d="M12 8h.01"></path></svg>
                <span class="dropdown-list">Support</span>
              </li>
              <li @click="signOut">
                <svg class="dropdown-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 16l4-4m0 0l-4-4m4 4H7"></path><path d="M7 2H3a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h4"></path></svg>
                <span class="dropdown-list">Sign Out</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Add the rest of your rows here -->
  </div>
</template>


<script>
export default {
  name: 'HomeNav',
  data() {
    return {
      isMenuOpen: false,
      profileImageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9x_UNMEEPTPvj2E_2G23vhebSnkTB5newlA&s'
    };
  },
  methods: {
    goToNotifications() {
      this.$router.push({ name: 'Notifications' });
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    goToProfile() {
      this.$router.push({ name: 'Profile' });
    },
    goToSupport() {
      this.$router.push({ name: 'Support' });
    },
    signOut() {
      // Logic for sign out
      console.log("Signing out...");
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

.home-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.dropdown-icon{
  margin-bottom: -5px;
}

.dropdown-list{
margin-left: 15px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.top-row {
  justify-content: space-between;
  align-items: center;
}

.logo-wrapper {
  background-color: #e8f5f4;
  border-radius: 10px;
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-text {
  margin-left: 5px;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  color: #127569;
}
.logo-text2 {
  margin-left: 1px;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  color: #000000;
}
.logo-dot {
  margin-top: -41px;
  font-size: 50px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  color: #127569;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.notification-wrapper {
  background-color: #e8f5f4;
  border-radius: 10px;
  width: 41px;
  height: 41px;
  display: flex;
  padding: 7px;
  margin-right: 5px;
  cursor: pointer;
}

.notification-icon {
  margin-left: 2px;
}

.menu-wrapper {
  position: relative;
}

.menu-icon {
  background-color: #e8f5f4;
  border-radius: 10px;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
  color: #127569;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 45px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 150px;
  text-align: left;
  padding: 10px 0;
  z-index: 10;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px 20px;
  cursor: pointer;
  color: #127569;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}

.dropdown-menu li:hover {
  background-color: #e8f5f4;
}
</style>
